import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { InitalserviceService } from 'src/app/services/initalservice.service';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
})
export class AddTeamComponent implements OnInit {
  teamName: string = '';
  id: string = null;
  title: string = '';
  modules: any;
  submitted: boolean = false;
  teamForm: FormGroup;
  zoneData: any;
  vehicleData: any;
  dimensionsUnitArray: any = [
    { value: 'feet', Name: 'Feet' },
    { value: 'meter', Name: 'Meter' },
  ];
  weightUnitArray: any = [
    { value: 'gm', Name: 'Gram' },
    { value: 'kg', Name: 'Kilogram' },
    { value: 'ton', Name: 'Ton' },
    { value: 'quintal', Name: 'Quintal' },
  ];
  type: any;
  settings: any;
  fileData: any;
  languageList: any;
  constructor(
    private https: HttpsService,
    private dialog: MatDialog,
    private common: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private initalservice: InitalserviceService,
    private translate:TranslateService,
    private fb: FormBuilder
  ) {
    if (localStorage['languages']) {
      this.languageList = this.common.decryptLocalStorage('languages');
    }
  }

  ngOnInit(): void {
    this.createForm();
    this.zoneList();
    this.vehicleList();
    this.modules = this.initalservice.modules();
    this.settings = this.common.decryptLocalStorage('settings');
  
    this.title = 'Add' + ' ' + this.modules?.teams;
    this.type = this.data.type;
    if (this.data.data) {
      (this.id = this.data.data._id),
        (this.title = 'Edit' + ' ' + this.modules?.teams);
      this.teamForm.patchValue(this.data.data);
      
      if(this.data.data.isPackage){
        this.changePickupDelivery(this.data.data.isPackage,this.data.data)
      }

      if(this.data.data.name_lng && this.data.data.name_lng.length > 0){
        this.teamForm.setControl(
          'name_lng',
          this.createNewResultArrayType(this.data.data.name_lng)
        );
      }else{
        const obj={
          lang:'en',
          value: this.data.data.name
        }
        this.teamForm.setControl(
          'name_lng',
          this.createNewResultArrayType([obj])
        );
    }
  }
}

  createForm() {
    this.teamForm = this.fb.group({
      name_lng: new FormArray([this.createGroupType('en')]),
      zoneIds: [[]],
      vehicleIds: [[]],
      isPackage:[false]
    });
  }
  changePickupDelivery(event,data?){
    if(event ===true){
        this.teamForm.addControl(
          'dimensions',
          this.fb.control(data?.dimensions ||'', [Validators.required])
        );
        this.teamForm.controls['dimensions'].updateValueAndValidity();
        this.teamForm.addControl('dimensionsUnit', this.fb.control(data?.dimensionsUnit ||''));
        this.teamForm.controls['dimensionsUnit'].updateValueAndValidity();
        this.teamForm.addControl(
          'maxWeight',
          this.fb.control(data?.maxWeight ||'', [Validators.required])
        );
        this.teamForm.controls['maxWeight'].updateValueAndValidity();
        this.teamForm.addControl('weightUnit', this.fb.control(data?.weightUnit ||''));
        this.teamForm.controls['weightUnit'].updateValueAndValidity();
        this.teamForm.addControl(
          'image',
          this.fb.control(data?.image ||'', [Validators.required])
        );
        this.teamForm.controls['image'].updateValueAndValidity();
      }else{
        this.removeControls();
      }
    

  }
  removeControls() {
    const controlsToRemove = ['dimensions', 'dimensionsUnit', 'maxWeight', 'weightUnit', 'image'];
    controlsToRemove.forEach(controlName => {
      this.teamForm.removeControl(controlName);
    });
  }


    // type FormArray all execution starts //

    getType() {
      return this.teamForm.get('name_lng') as FormArray;
    }
  
    createGroupType(type?: string) {
      return new FormGroup({
        lang: new FormControl(type || '', [Validators.required]),
        value: new FormControl('', [Validators.required]),
      });
    }
  
    onSelectionChangeType(index: number): void {
      if (
        this.teamForm.value.name_lng &&
        this.teamForm.value.name_lng.length > 1 &&
        index == 0
      ) {
        this.teamForm.value.name_lng.forEach((val, i) => {
          this.getType().removeAt(i);
        });
      }
    }
  
    btn_diabledType(item) {
      let found = false;
      this.teamForm.value.name_lng.forEach((list) => {
        if (list.lang == item.value) {
          found = true;
        }
      });
      return found;
    }
  
    addMultiNameType() {
      this.getType().push(this.createGroupType());
    }
  
    removeStoreTypeType(index) {
      this.getType().removeAt(index);
    }
  
    createNewResultArrayType(data: any): FormArray {
      return new FormArray(
        data.map(
          (option, i) =>
            new FormGroup({
              lang: new FormControl(option.lang),
              value: new FormControl(option.value),
            })
        )
      );
    }


  close() {
    this.dialog.closeAll();
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.fileData = event.target.files[0];
      var ext = event.target.files[0].name.split('.');
      ext = ext[ext.length - 1];
      if (
        ext != 'jpg' &&
        ext != 'JPG' &&
        ext != 'jpeg' &&
        ext != 'JPEG' &&
        ext != 'png' &&
        ext != 'PNG' &&
        ext != 'webp' &&
        ext != 'WEBP' &&
        ext != 'ico' &&
        ext != 'ICO'
      ) {
        return this.common.snackbarError(
          this.translate.instant('dispatcher_latest.img_validation_err'),
          '',
          2000
        );
      } else {
        this.https.fileUpload(this.fileData).subscribe((res: any) => {
          this.teamForm.controls['image'].setValue(res.data);
        });
      }
    }
  }

  zoneList() {
    this.https.httpGetWithHeader('zone').subscribe(
      (res: any) => {
        this.zoneData = res.data;
        let languageList = this.languageList.filter(
          (language) => language.isSelected
        );
        // const selectedLangValue = languageList[0].value;
        // this.zoneData = this.zoneData.filter((val) => {
        //   if (val.name_lng && val.name_lng.length > 0) {
        //     const data = val.name_lng.find(
        //       (list) => list.lang === selectedLangValue
        //     );
        //     if (data) {
        //       val.name = data.value;
        //       return true;
        //     }else{
        //       const name = val.name_lng.find(list => list.lang === 'en');
        //       val.name = name.value || '';
        //       return true;
        //     }
        //     return false;
        //   }
        //   return true;
        // });   
      },
      (error) => console.log(error)
    );
  }

  vehicleList() {
    this.https.httpGetWithHeader('vehicle').subscribe(
      (res: any) => {
        this.vehicleData = res.data;        
      },
      (error) => console.log(error)
    );
  }

  btn_add() {
    if (this.teamForm.valid) {
      const body = this.teamForm.value;
      this.type == 'edit' &&
        this.https
          .httpPutWithHeader('team', this.id, body)
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.common.snackbarSuccess(this.translate.instant('dispatcher_latest.succ_update'), '', 2000);
              this.dialog.closeAll();
            }
          });

      this.type == 'add' &&
        this.https
          .httpPostWithHeader('team', body, true)
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.dialog.closeAll();
              this.common.snackbarSuccess(this.translate.instant('dispatcher_latest.succ_update'), '', 2000);
            }
          });
    } else {
      this.teamForm.markAllAsTouched();
    }
  }
}
