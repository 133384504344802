<div class="wlk_wrp" mat-dialog-close>
  <figure><i class="fa-solid fa-xmark"></i></figure>
</div>
<div class="hd mb-3">
  <h2>{{'addRequiredModal.AddDocuments' | translate}}</h2>
</div>
<form action="" [formGroup]="docForm">
  <ng-container formArrayName="data">
    <ng-container
      [formGroupName]="i"
      *ngFor="let list of docArray().controls; let i = index"
    >
      <ul class="modal_wrp">
        <li class="flds">
          <mat-label>{{'addRequiredModal.Type' | translate}}</mat-label>
          <mat-select
            class="form_control"
            placeholder="{{'requiredDocuments.Type'|translate}}"
            formControlName="type"
          >
            <mat-option [value]="0">{{'addRequiredModal.Driver' | translate}}</mat-option>
            <mat-option [value]="1">{{'addRequiredModal.vehicle' | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && list.get('type').hasError('required')">
            {{'addRequiredModal.Type' | translate}} {{'common.isrequired' | translate}}
          </mat-error>
        </li>
        <li class="flds">
          <ng-container formArrayName="name_lng">
            <ng-container
              *ngFor="let data of getName_lng(i).controls; let index = index"
              [formGroupName]="index"
            >
              <div style="display: flex;">
                <mat-label>{{'dispatcher_latest.Document_Name'|translate}}</mat-label>
                <button
                  class="add-on-btn plus"
                  (click)="addMultiName(i)"
                  *ngIf="
                    index == 0 &&
                    docArray().at(index).value.name_lng.length < languageList.length
                  "
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
                <button
                  class="add-on-btn plus"
                  (click)="removeStoreType(i,index)"
                  *ngIf="index > 0"
                >
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </button>
              </div>
              <div class="input-group pt-2" *ngIf="languageList && languageList.length>1">
                <mat-select
                  class="form-control"
                  formControlName="lang"
                  placeholder="{{'dispatcher_latest.SelectLanguage'|translate}}"
                  (selectionChange)="onSelectionChange(i,index)"
                >
                  <mat-option
                    *ngFor="let item of languageList"
                    [value]="item.value"
                    [disabled]="index > 0 ? btn_datadiabledType(item,i) : false"
                    >{{ item.language }}</mat-option
                  >
                </mat-select>
                <div *ngIf="data.controls.lang.errors" class="invalid-feedback">
                  <div *ngIf="data.controls.lang.errors.required">
                    {{'TEAMSPAGE.Language' | translate}} {{'TEAMSPAGE.isrequired' | translate}} 
                  </div>
                </div>
              </div>  
    
              <mat-form-field class="example-full-width" floatLabel="never">
                <input matInput placeholder="{{'dispatcher_latest.Document_Name'|translate}}" class="form_control" formControlName="value"
                    type="text" appNoSpaceAtFirst>
                  </mat-form-field>
                  <mat-error
                  *ngIf="
                    submitted && data.controls['value'].hasError('required')
                  "
                  >  {{'TEAMSPAGE.Name' | translate}} {{'TEAMSPAGE.isrequired' | translate}}
                </mat-error>

            </ng-container>
          </ng-container>
        </li>

        <li class="flds">
          <mat-label>{{'addRequiredModal.RequireType' | translate}}</mat-label>
          <mat-select
            class="form_control"
            placeholder="{{'requiredDocuments.RequireType'|translate}}"
            formControlName="requireType"
          >
          <mat-option value="FRONT">{{'addRequiredModal.Front' | translate}}</mat-option>
          <mat-option value="BACK">{{'addRequiredModal.Back' | translate}}</mat-option>
          <mat-option value="BOTH">{{'addRequiredModal.Both' | translate}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="submitted && list.get('requireType').hasError('required')"
          >
          {{'addRequiredModal.RequireType' | translate}} {{'common.isrequired' | translate}}
          </mat-error>
        </li>

        <li class="flds">
          <mat-label>{{'addRequiredModal.Require' | translate}}</mat-label>
          <mat-select
            class="form_control"
            placeholder="{{'requiredDocuments.Require'|translate}}"
            formControlName="require"
          >
          <mat-option [value]="true">{{'addRequiredModal.Yes' | translate}}</mat-option>
          <mat-option [value]="false">{{'addRequiredModal.No' | translate}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="submitted && list.get('require').hasError('required')"
          >
          {{'addRequiredModal.Require' | translate}} {{'common.isrequired' | translate}}
          </mat-error>
        </li>

        <li class="flds">
          <mat-label>{{'addRequiredModal.DocumentNumber' | translate}}</mat-label>
          <mat-select
            class="form_control"
            placeholder="{{'addRequiredModal.DocumentNumber' | translate}}"
            formControlName="certificateNumber"
          >
          <mat-option [value]="true">{{'addRequiredModal.Yes' | translate}}</mat-option>
          <mat-option [value]="false">{{'addRequiredModal.No' | translate}}</mat-option>
          </mat-select>
        </li>

        <li class="flds">
          <mat-label>{{'addRequiredModal.IssueDate' | translate}}</mat-label>
          <mat-select
            class="form_control"
            placeholder="{{'addRequiredModal.IssueDate' | translate}}"
            formControlName="isIssueDate"
          >
            <mat-option [value]="true">{{'addRequiredModal.Yes' | translate}}</mat-option>
            <mat-option [value]="false">{{'addRequiredModal.No' | translate}}</mat-option>
          </mat-select>
        </li>

        <li class="flds">
          <mat-label>{{'addRequiredModal.ExpiryDate' | translate}}</mat-label>
          <mat-select (selectionChange)="btn_change($event.value)"
            class="form_control"
            placeholder="{{'addRequiredModal.ExpiryDate' | translate}}"
            formControlName="isExpiryDate"
          >
          <mat-option [value]="true">{{'addRequiredModal.Yes' | translate}}</mat-option>
          <mat-option [value]="false">{{'addRequiredModal.No' | translate}}</mat-option>
          </mat-select>
        </li>

        <li class="flds" *ngIf="buffer">
          <mat-label>{{'dispatcher_latest.Buffer_Time'|translate}}<sup>({{'addRequiredModal.InMin' | translate}})</sup></mat-label>
          <mat-form-field
            appearance="fill"
          >
            <input class="form_control"
              matInput
              placeholder="{{'dispatcher_latest.Buffer_Time'|translate}}"
              formControlName="{{'dispatcher_latest.expiryDays'|translate}}"
              type="number"
            />
          </mat-form-field>
          <mat-error *ngIf="submitted && list.get('expiryDays').hasError('required')">
            {{'dispatcher_latest.Buffer_tym_req'|translate}}
          </mat-error>
        </li>

        <li
          class="add_icn flds"
          *ngIf="i == 0 && data.type == 'add'"
          (click)="onAddingFormDropOff()"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </li>

        <li
          class="add_icn flds"
          *ngIf="i != 0"
          (click)="onRemovingFormDropOff(i)"
        >
          <i class="fa fa-minus" aria-hidden="true"></i>
        </li>
      </ul>
      <div class="hd mb-3 pt-2">
        <h2>Notification</h2>
      </div>
      <ng-container formArrayName="driverDocumentExpirySetting">
        <ng-container
          *ngFor="let data of getTypeMain(i).controls; let index = index"
          [formGroupName]="index"
        >
        <li
        class="add_icn flds plus4"
        *ngIf="index == 0"
        (click)="addMultiNameMain(i)"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>
      </li>
      <li
        class="add_icn flds plus4"
        *ngIf="index > 0"
        (click)="removeStoreTypeMain(index,i)"
      >
        <i class="fa fa-minus" aria-hidden="true"></i>
      </li>
          <div class="whte_lble">
            <div class="wht_lbl_dv">
              <div class="cmbng_dv cardW">
                <div class="form-group">
                  <label>{{"VEHICLEPAGE.Days"|translate}}</label>
                  <div class="err_ads_msg">
                    <mat-form-field
                      class="example-full-width"
                      floatLabel="never"
                    >
                      <input
                        matInput
                        placeholder="{{'VEHICLEPAGE.Days'|translate}}"
                        type="number"
                        class="form_control"
                        formControlName="day"
                      />
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        submitted &&
                        data.get('day').hasError('required')
                      "
                    >
                      {{'VEHICLEPAGE.Daysisrequired'|translate}}
                    </mat-error>
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'NOTIFICATION.SendNotification'|translate}}</label>
                  <div class="err_ads_msg">
                    <mat-select
                      class="example-full-width"
                      placeholder="{{'NOTIFICATION.SendNotification'|translate}}"
                      formControlName="isSendNotification"
                    >
                      <mat-option [value]="true">{{"GeneralSettings.Yes"|translate}}</mat-option>
                      <mat-option [value]="false">{{"GeneralSettings.No"|translate}}</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        submitted &&
                        data.get('isSendNotification').hasError(
                          'required'
                        )
                      "
                    >
                      {{'dispatcher_latest.snd_noti_msg'|translate}}
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="cmbng_dv cardW">
                <ng-container formArrayName="dataText">
                  <ng-container
                    *ngFor="
                      let list of getType(i,index).controls;
                      let j = index
                    "
                    [formGroupName]="j"
                  >
                    <div class="form-group" *ngIf="languageList && languageList.length>1">
                      <label>{{'GEOFENCEPAGE.Language'|translate}}</label>
                      <div class="err_ads_msg">
                        <mat-select
                          class="form-control"
                          formControlName="lang"
                          placeholder="{{'GEOFENCEPAGE.SelectLanguage'|translate}}"
                          (selectionChange)="
                            onSelectionChangeType(i, index)
                          "
                        >
                          <mat-option
                            *ngFor="let item of languageList"
                            [value]="item.value"
                            [disabled]="
                              index > 0 ? btn_diabledType(i,index, item) : false
                            "
                            >{{ item.language }}</mat-option
                          >
                        </mat-select>
                        <mat-error
                          *ngIf="
                            submitted &&
                            list.get('lang').hasError('required')
                          "
                        >
                          {{'dispatcher_latest.Lang_req_msg'|translate}}
                        </mat-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{'NOTIFICATION.NotificationTitle'|translate}}</label>
                      <div class="err_ads_msg">
                        <mat-form-field
                          class="example-full-width"
                          floatLabel="never"
                        >
                          <input
                            matInput
                            placeholder="{{'NOTIFICATION.NotificationTitle'|translate}}"
                            type="text"
                            class="form_control"
                            formControlName="notificationTitle"
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            submitted &&
                            list.get('notificationTitle').hasError(
                              'required'
                            )
                          "
                        >
                          {{'dispatcher_latest.Noti_title_req_msg'|translate}}
                        </mat-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{'dispatcher_latest.Noti_title_msg'|translate}}</label>
                      <div class="err_ads_msg">
                        <mat-form-field
                          class="example-full-width"
                          floatLabel="never"
                        >
                          <input
                            matInput
                            placeholder="{{'dispatcher_latest.Noti_title_msg'|translate}}"
                            type="text"
                            class="form_control"
                            formControlName="notificationMessage"
                          />
                        </mat-form-field>
                        <mat-error
                          *ngIf="
                            submitted &&
                            list.get('notificationMessage').hasError(
                              'required'
                            )
                          "
                        >
                          {{'dispatcher_latest.Noti_msg_req_msg'|translate}}
                        </mat-error>
                      </div>
                    </div>
                    <li
                      class="add_icn flds"
                      *ngIf="
                        j == 0 &&
                        getTypeMain(i).at(j).value.dataText.length <
                          languageList.length
                      "
                      (click)="addMultiNameType(j, index)"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </li>
                    <li
                      class="add_icn flds"
                      *ngIf="j > 0"
                      (click)="removeStoreTypeType(j, index)"
                    >
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    </li>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form>

<div class="alt_btn">
  <button class="btn btn-primary" (click)="btn_add()">{{'requiredDocuments.Add'|translate}}</button>
</div>
