import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-delivey-proof',
  templateUrl: './show-delivey-proof.component.html',
  styleUrls: ['./show-delivey-proof.component.scss']
})
export class ShowDeliveyProofComponent implements OnInit {
  slideConfig = {
    "slidesToShow": 2, "slidesToScroll": 1, responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  constructor(public dialogRef: MatDialogRef<ShowDeliveyProofComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
