import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss'],
})
export class AddDocumentComponent implements OnInit, OnDestroy {
  docForm: FormGroup;
  mainFormDropOff: FormArray;
  submitted: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedLanguagesName: any[] = [];
  languageList: any[] = [];
  documentsNotification: any;
  buffer: boolean=false;
  constructor(
    private _http: HttpsService,
    private fb: FormBuilder,
    private _commonService: CommonService,
    public dialogRef: MatDialogRef<AddDocumentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (localStorage['languages']) {
      this.languageList = this._commonService.decryptLocalStorage('languages');
    }
  }

  ngOnInit(): void {
    this.createForm();
    if (this.data.type == 'edit') {
      this.getById();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getById() {
    this._http
      .httpGetHeaderById('getRequiredDocument', this.data.data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.documentsNotification = res.data.driverDocumentExpirySetting;
          let data: any[] = [
            {
              type: res.data.type,
              requireType: res.data.requireType,
              require: res.data.require,
              isExpiryDate: res.data.isExpiryDate,
              isIssueDate: res.data.isIssueDate,
              name_lng:res.data.name_lng,
              certificateNumber: res.data.certificateNumber,
              expiryDays:res.data.expiryDays,
              driverDocumentExpirySetting:res.data.driverDocumentExpirySetting
            },
          ];

          this.docForm.setControl(
            'data',
            this.createNewResultArrayDropOff(data)
          );
          if(res.data.name_lng && res.data.name_lng.length > 0) {
            this.docForm.get('data')['controls'][0].setControl(
              'name_lng',
              this.createResultArrayType(res.data.name_lng)
            );
          }else{
            const obj={
              lang:'en',
              value: res.data.name
            }
            this.docForm.get('data')['controls'][0].setControl(
              'name_lng',
              this.createResultArrayType([obj])
            );
          }

          if(this.documentsNotification && this.documentsNotification.length > 0) {
            this.docForm.controls['data']['controls'][0].setControl('driverDocumentExpirySetting',this.createNewResultArrayMain(this.documentsNotification));
            this.documentsNotification.forEach((val,i)=>{
              if(val.dataText && val.dataText.length > 0){
                this.docForm.controls['data']['controls'][0].get('driverDocumentExpirySetting')['controls'][0].setControl('dataText',this.createNewResultArrayType(val?.dataText));
              }
            })
        }
        }
      });
  }


  createResultArrayType(data: any): FormArray {
    return new FormArray(
      data.map(
        (option, i) =>
          new FormGroup({
            lang: new FormControl(option.lang),
            value: new FormControl(option.value),
          })
      )
    );
  }

  createForm() {
    this.docForm = this.fb.group({
      data: this.fb.array([this.createForms()]),
    });
  }

  docArray(): FormArray {
    return this.docForm.get('data') as FormArray;
  }

  createForms() {
    return this.fb.group({
      type: ['', [Validators.required]],
      requireType: ['', [Validators.required]],
      require: ['', Validators.required],
      name_lng: new FormArray([this.createdataGroupType('en')]),
      isExpiryDate: [false],
      isIssueDate: [false],
      certificateNumber: [false],
      expiryDays: [0],
      driverDocumentExpirySetting: this.fb.array([this.createDataMain()]),
    });
  }

  onAddingFormDropOff() {
    this.docArray().push(this.createForms());
  }

  onRemovingFormDropOff(i: any) {
    this.docArray().removeAt(i);
  }

  createNewResultArrayDropOff(data: any): FormArray {
    return new FormArray(
      data.map(
        (option, i) =>
          new FormGroup({
            type: new FormControl(option.type, [Validators.required]),
            requireType: new FormControl(option.requireType, [
              Validators.required,
            ]),
            name_lng: new FormArray([this.createResultArrayType(option?.name_lng)]),
            require: new FormControl(option.require, [Validators.required]),
            isExpiryDate: new FormControl(option.isExpiryDate),
            isIssueDate: new FormControl(option.isIssueDate),
            certificateNumber: new FormControl(option.certificateNumber),
            driverDocumentExpirySetting:this.fb.array([this.createDataMain()]),
            expiryDays: new FormControl(option.expiryDays)
          })
      )
    );
  }

  //Name Language
  getName_lng(i) {
    return this.docArray().at(i).get('name_lng') as FormArray;
  }

  btn_datadiabledType(item,i) {
    let found = false;
    this.docArray().at(i).value.name_lng.forEach((list) => {
      if (list.lang == item.value) {
        found = true;
      }
    });
    return found;
  }

  createdataGroupType(type?: string) {
    return new FormGroup({
      lang: new FormControl(type || '', [Validators.required]),
      value: new FormControl('', [Validators.required]),
    });
  }

  onSelectionChange(i, index: number): void {
    if (
      this.docArray().at(i).value.name_lng &&
      this.docArray().at(i).value.name_lng.length > 1 &&
      index == 0
    ) {
      this.docArray().at(i).value.name_lng.forEach((val, j) => {
          this.getName_lng(i).removeAt(j);
      });
    }
  }

  addMultiName(i) {
    this.getName_lng(i).push(this.createdataGroupType());
  }

  removeStoreType(i, index) {
    this.getName_lng(i).removeAt(index);
  }

  createNewResultArray(data: any): FormArray {    
    return new FormArray(
      data.map(
        (option, i) =>
          new FormGroup({
            lang: new FormControl(option.lang),
            value: new FormControl(option.value),
            title:new FormControl(option.title)
            
          })
      )
    );
  }

  //----------------------------------------------------------------

  //Document Notification
  getTypeMain(i) {
    return this.docArray().at(i).get('driverDocumentExpirySetting') as FormArray;
  }

  createDataMain() {
    return new FormGroup({
      dataText: this.fb.array([this.createGroupType('en')]),
      day: new FormControl('', [Validators.required]),
      isSendNotification: new FormControl('', [Validators.required]),
    });
  }

  addMultiNameMain(i) {
    this.getTypeMain(i).push(this.createDataMain());
  }

  removeStoreTypeMain(index,j) {
    this.getTypeMain(j).removeAt(index);
  }

  createNewResultArrayMain(data: any): FormArray {
    return new FormArray(
      data.map(
        (option, i) =>
          new FormGroup({
            dataText: new FormControl(option.dataText),
            day: new FormControl(option.day),
            isSendNotification: new FormControl(option.isSendNotification),
          })
      )
    );
  }

  //----------------------------------------------------------------

  // language FormArray all execution starts //

  getType(i, j) {
    return this.getTypeMain(i).at(j).get('dataText') as FormArray;
  }

  createGroupType(type?: string) {
    return new FormGroup({
      lang: new FormControl(type || '', [Validators.required]),
      notificationTitle: new FormControl('', [Validators.required]),
      notificationMessage: new FormControl('', [Validators.required]),
    });
  }

  onSelectionChangeType(i, index: number): void {
    if (
      this.docForm.value.dataText &&
      this.docForm.value.dataText.length > 1 &&
      index == 0
    ) {
      this.getTypeMain(i)
        .at(index)
        .value.dataText.forEach((val, i) => {
          this.getType(i,index).removeAt(i);
        });
    }
  }

  btn_diabledType(i,index,item) {
    let found = false;
    this.getTypeMain(i).at(index).value.dataText.forEach((list) => {
        if (list.lang == item.value) {
          found = true;
        }
      });
    return found;
  }

  addMultiNameType(i, index) {
    this.getType(i,index).push(this.createGroupType());
  }
  // addMultinewNameType(){
  //   this.getType().push(this.createdataGroupType());
  // }
  // removedataStoreTypeType(index){
  //   this.getType().removeAt(index);
  // }

  removeStoreTypeType(i, index) {
    this.getType(i,index).removeAt(index);
  }

  createNewResultArrayType(data: any): FormArray {
    return new FormArray(
      data.map(
        (option, i) =>
          new FormGroup({
            lang: new FormControl(option.lang),
            notificationTitle: new FormControl(option.notificationTitle),
            notificationMessage: new FormControl(option.notificationMessage),
          })
      )
    );
  }

  btn_add() {
    this.submitted = true;
    if (this.docForm.valid) {
      const body = this.docForm.value;
      this.data.type != 'edit' &&
        this._http
          .httpPostWithHeader('multipleRequiredDocument', body, false)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this._commonService.snackbarSuccess(res.message, '', 2000);
              this.dialogRef.close('yes');
            }
          });

      this.data.type == 'edit' &&
        this._http
          .httpPutWithHeaderBodyAndId(
            'requiredDocument',
            this.data.data,
            body.data[0],
            false
          )
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this._commonService.snackbarSuccess(res.message, '', 2000);
              this.dialogRef.close('yes');
            }
          });
    } else {
      this.docForm.markAllAsTouched();
    }
  }

  btn_change(value){
    this.buffer=value;
  }

}
