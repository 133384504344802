import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common/common.service';
import * as CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class SetInterceptorService implements HttpInterceptor {
  iv = CryptoJS.enc.Base64.parse(environment.iv); //giving empty initialization vector
  key = CryptoJS.SHA256(environment.key); //hashing the key using SHA256
  encryptedString: any;
  constructor(private commonService: CommonService,private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let token = null;  
    let appId=null;
    appId=localStorage.getItem('appId');
  
    if(localStorage['accessToken']){
        let a=localStorage.getItem('accessToken');
        if(a.length == 192){
          localStorage.clear();
          this.router.navigateByUrl('/auth/login');
        }else{
          token=this.commonService.decryptLocalStorage('accessToken')
        }
    }
    let authorization: any = {
      appId: appId ? appId : '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      language : localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
    }
    if(token){
      authorization['authorization']=token ? `Bearer ${token}` : `N/A`
    }

    let authReq;
    authReq = req.clone({
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        hash: this.encryptData(authorization),
        devicetype: 'web',
      }),
    });
    
    if (
      (req.method.toLowerCase() == "post" ||
        req.method.toLowerCase() == "put") &&
      req.body instanceof FormData
    ) {
      authReq = req.clone({
          headers: new HttpHeaders({
            hash: this.encryptData(authorization),
            devicetype: 'web',
        }),
      });
    }else if(req.url.includes('https://maps.googleapis.com/maps/api/distancematrix/json')){
      authReq = req.clone({
        headers: new HttpHeaders({
          // Authorization: finalToken ? `Bearer ${finalToken}` : `Bearer Guest ${fcmKey}`,
        }),
      });
    }
    return authReq;
  }
  encryptData(data) {
    const currentTimestamp = dayjs.valueOf();
    data['date'] = currentTimestamp;
    this.encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return this.encryptedString.toString();
  }
}
