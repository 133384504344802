import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class Constant {
  BOOKING_STATUS: any = {
    DEFAULT: 0,
    PENDING: 1,
    ACCEPTED: 2,
    START_PICKUP: 3,
    ARRIVED_AT_PICKED: 4,
    PICKED: 5,
    PICKED_ALL: 6,
    START_DELIVERY: 7,
    COMPLETED: 8,
    SCHEDULED: 9,
    CANCELED: 10,
    ARRIVED_AT_DROP_OFF: 11,
    WAITING_FOR_NEXT_TRIP: 13,
  };
  SHOW_BOOKING_STATUS: any = {
    0: 'STATUSES.Default.',
    1: 'STATUSES.Pending',
    2: 'STATUSES.Accepted',
    3: 'STATUSES.Waytopickup',
    4: 'STATUSES.Atpickup',
    5: 'STATUSES.Picked',
    6: 'STATUSES.Pickedall',
    7: 'STATUSES.Waytodrop-off',
    8: 'STATUSES.Delivered',
    9: 'STATUSES.Scheduled',
    10: 'STATUSES.Cancelled',
    11: 'STATUSES.Atdropoff',
    13: 'STATUSES.WaitingForNextTrip',
  };
  PAYMENT_STATUS: any = {
    1: 'CASH',
    2: 'CARD',
    3: 'WALLET',
    4: 'K-NET',
  };
  TYPE: any = {
    PERCENTAGE: 0,
    FLAT: 1,
  };
  EMP_TYPE: any = {
    0: 'Salaried',
    1: 'Salaried + commission',
    2: 'Commission',
  };
  PUSH_TYPE_KEYS: any = {
    DEFAULT: 0,
    NEW_BOOKING_DRIVER: 1,
    DRIVER_ACCEPT_DELIVERY: 2,
    DRIVER_ON_THE_WAY_PICKUP: 3,
    DRIVER_ARRIVED_AT_PICKUP: 4,
    STARTED_DELIVERY_BY_DRIVER: 5,
    COMPLETED_DELIVERY_BY_DRIVER: 6,
    CANCEL_DELIVERY_BY_DRIVER: 7,
    CANCEL_BOOKING_BY_CUSTOMER: 8,
    DRIVER_SAY_THANKS: 9,
    REASSIGN_DELIVERY: 10,
    BOOKING_ASSIGN_BY_ADMIN: 11,
    NEW_MESSAGE: 12,
    CANCEL_BOOKING_BY_ADMIN: 13,
    DRIVER_ACCOUNT_APPROVE: 14,
    BOOKING_IS_UPDATED: 15,
    NEW_SUPPORT_MESSAGE: 16,
    UNREAD_MESSAGE_FROM_USER: 17,
    UNREAD_MESSAGE_FROM_SUPPORT: 18,
    SEND_PUSH_NOTIFICATION_BY_ADMIN: 19,
    SEND_MAIL_NOTIFICATION_BY_ADMIN: 20,
  };
  TICKET_TYPE: any = {
    INQUIRY: 1,
    COMPLAIN: 2,
    CHARGES: 3,
  };
  CALL_TYPE: any = {
    INBOUND: 1,
    OUTBOUND: 2,
    LIVE_CHAT: 3,
  };
  SHOW_TICKET_TYPE: any = {
    1: 'Inquery',
    2: 'Complain',
    3: 'Charges',
  };
  SHOW_CALL_TYPE: any = {
    1: 'Inbound',
    2: 'Outbound',
    3: 'Live chat',
  };
  TICKET_STATUS: any = {
    CLOSED: 1,
    ESCALATED: 2,
  };
  SHOW_TICKET_STATUS: any = {
    1: 'Closed',
    2: 'Escalated',
  };
  CLOSING_ISSUES: any = {
    GENERAL_INQUIRY: 1,
    CHECK_ADDRESS: 2,
    FOLLOW_UP_CUSTOMER: 3,
    OTHER: 4,
    UNAVAILABILITY_OF_TRUCKS: 5,
    TECHNICAL_ISSUE: 6,
    MISSING_ITEMS: 7,
    DRIVER_MISTAKE: 8,
    CUSTOMER_FAULT: 9,
    APPLICATION_ISSUE: 10,
    ACCOUNTANT_ISSUE: 11,
    CHNAGE_AMOUNT: 12,
    CANCEL: 13,
  };
  SHOW_CLOSING_ISSUES: any = {
    1: 'GENERAL INQUIRY',
    2: 'CHECK ADDRESS',
    3: 'FOLLOW UP CUSTOMER',
    4: 'OTHER',
    5: 'UNAVAILABILITY OF TRUCKS',
    6: 'TECHNICAL ISSUE',
    7: 'MISSING ITEMS',
    8: 'DRIVER MISTAKE',
    9: 'CUSTOMER FAULT',
    10: 'APPLICATION ISSUE',
    11: 'ACCOUNTANT ISSUE',
    12: 'CHNAGE AMOUNT',
    13: 'CANCEL',
  };
  CHARGE_TYPE: any = {
    ADD: 1,
    REMOVE: 2,
    CANCEL: 3,
  };
  CUSTOMER_CREDIT: any = {
    CREDITS: 1,
    REFUND: 2,
  };
  BOOKINGS_TYPES: any = {
    PENDING: 0,
    UPCOMING: 1,
    ONGOING: 2,
    COMPLETED: 3,
    CANCELLED: 4,
    ALL: 5,
  };
  modules: any;
  MODULES: any = {
    basic: [
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        route: '/home',
        moduleCode: 1,
        // displayName: 'Home',
        displayName:'SIDEBAR.Home',
        icon: `<i class="fa fa-home" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/bookings',
        loginPage: {
          LoginIn: 'Login In', // This is the property you want to access
          // Other properties here
        },
        moduleCode: 2,
        // displayName: 'Bookings',
        displayName:'SIDEBAR.Bookings',
        icon: `<i class="fa fa-dropbox" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/customer',
        moduleCode: 3,
        // displayName: 'customer',
        displayName:'SIDEBAR.customer',
        icon: `<i class="fa fa-user" aria-hidden="true"></i>`,
      },

      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 4,
        route: '/vehicle',
        // displayName: 'vehicle',
        displayName:'SIDEBAR.vehicle',
        icon: `<i class="fa fa-car" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 14,
        route: '/packages',
        displayName: 'dispatcher_latest.Packages',
        icon: `<i class="fa-solid fa-money-bill"></i>`,
      },

      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 5,
        route: '/driver',
        // displayName: 'driver',
        displayName:'SIDEBAR.driver',
        icon: `<i class="fa fa-user-secret" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 13,
        route: '/sos',
        // displayName: 'SOS',
        displayName:'SIDEBAR.SOS',
        icon: `<i class="fa-solid fa-tower-broadcast"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 6,
        route: '/zone',
        // displayName: 'Geofence',
        displayName:'SIDEBAR.Geofence',
        icon: `<i class="fa fa-map-marker" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 7,
        route: '/teams',
        // displayName: 'teams',
        displayName:'SIDEBAR.teams',
        icon: `<i class="fa fa-users" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: true,
        isFilter: true,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 8,
        route: '/pricing',
        // displayName: 'pricing',
        displayName:'SIDEBAR.pricing',
        icon: `<i class="fa fa-google-wallet" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/reports/payouts',
        moduleCode: 9,
        // displayName: 'Reports',
        displayName:'SIDEBAR.Reports',
        icon: `<i class="fa fa-area-chart" aria-hidden="true"></i>`,
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/settings/general',
        moduleCode: 10,
        // displayName: 'Settings',
        displayName:'SIDEBAR.Settings',
        icon: '<i class="fa fa-cog" aria-hidden="true"></i>',
      },  
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/tools/refer',
        moduleCode: 11,
        // displayName: 'Tools',
        displayName:'SIDEBAR.Tools',
        icon: '<i class="fa fa-gg-circle" aria-hidden="true"></i>',
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/shuttle/location',
        moduleCode: 15,
        // displayName: 'Shuttle',
        displayName: 'SIDEBAR.Shuttle',

        icon: '<i class="fa fa-bus" aria-hidden="true"></i>',
      },

      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/notification',
        moduleCode: 16,
        // displayName: 'Notification',
        displayName:'SIDEBAR.Notification',
        icon: '<i class="fa fa-bus" aria-hidden="true"></i>',
      },
      {
        isAdd: true,
        isEdit: true,
        isView: true,
        isDelete: true,
        isSort: true,
        isFilter: true,
        isSetStatus: true,
        isBlocked: false,
        isDeleted: false,
        route: '/hiring',
        moduleCode: 17,
        displayName: 'dispatcher_latest.Hiring_Packages',
        icon: '<i class="fa fa-fire" aria-hidden="true"></i>',
      },
    ],
    settings: [
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.1,
        displayName:'SIDEBAR.GeneralSettings',
        route: '/settings/general',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.2,
        displayName:'SIDEBAR.Customize',
        route: '/settings/customize',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.3,
        displayName:'SIDEBAR.BookingAllocation',
        route: '/settings/bookingAllocation',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.4,
        displayName:'SIDEBAR.Plugins',
        route: '/settings/newplugin',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.5,
        displayName:'SIDEBAR.API',
        route: '/settings/api',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.6,
        displayName:'SIDEBAR.WebHooks',
        route: '/settings/webHooks',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.7,
        displayName:'SIDEBAR.Billing',
        route: '/settings/billing',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.8,
        displayName:'SIDEBAR.Tags',
        route: '/settings/tags',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.9,
        displayName:'SIDEBAR.RequiredDocuments',
        route: '/settings/documents',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.11,
        displayName:'SIDEBAR.CMS',
        route: '/settings/cms',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.12,
        displayName:'SIDEBAR.SubAdmin',
        route: '/settings/subAdmin',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.13,
        displayName:'SIDEBAR.CustomForms',
        route: '/settings/forms',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.15,
        displayName:'SIDEBAR.Walkthrough',
        route: '/settings/walkthrough',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.16,
        displayName:'SIDEBAR.Vehicle',
        route: '/settings/vehcile',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.19,
        displayName:'SIDEBAR.PlacesCharge',
        route: '/settings/place-charge',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.21,
        displayName:'SIDEBAR.QuestionAnswer',
        route: '/settings/Question-Answer',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.22,
        displayName:'SIDEBAR.StatusConfiguration',
        route: '/settings/config',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 10.23,
        displayName:'SIDEBAR.Tax',
        route: '/settings/tax',
      },
    ],
    reports: [
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 9.1,
        // displayName: 'Driver Payouts',
        displayName:'SIDEBAR.Payouts',
        route: '/reports/payouts',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 9.2,
        // displayName: 'Pending Payouts',
        displayName:'SIDEBAR.PendingPayouts',
        route: '/reports/requests',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 9.3,
        // displayName: 'Active Areas',
        displayName:'SIDEBAR.ActiveAreas',
        route: '/reports/areas',
      },
    ],
    tools: [
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 11.3,
        displayName: 'SIDEBAR.ReferralEarn',
        route: '/tools/refer',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 11.2,
        // displayName: 'Surcharge',
        displayName:'SIDEBAR.Surcharge',
        route: '/tools/surCharge',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 11.4,
        displayName: 'dispatcher_latest.Surcharge_Pricing',
        route: '/tools/surCharge-Pricing',
      },
    ],
    shuttle: [
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 15.1,
        // displayName: 'Shuttle Location',
        displayName:'SIDEBAR.ShuttleLocation',
        route: '/shuttle/location',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 15.2,
        // displayName: 'Shuttle Route',
        route: '/shuttle/routes',
        displayName:'SIDEBAR.ShuttleRoute',
      },
      {
        isAdd: false,
        isEdit: false,
        isView: true,
        isDelete: false,
        isSort: false,
        isFilter: false,
        isSetStatus: false,
        isBlocked: false,
        isDeleted: false,
        moduleCode: 15.3,
        displayName: 'SIDEBAR.Bookings',
        route: '/shuttle/bookings',
      },
    ],
  };
  featureDetail = [
    {
      name: 'Stripe',
      description: 'Configure Stripe to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/stripe_logo.svg',
      status: false,
      paymentMethod: 1,
    },
    {
      name: 'RazorPay',
      description: 'Configure RazorPay to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/razorpay-logo.svg',
      status: false,
      paymentMethod: 2,
    },
    {
      name: 'GreenPay',
      description: 'Configure GreenPay to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/Greenpay1.png',
      status: false,
      paymentMethod: 3,
    },
    {
      name: 'Dibsy',
      description: 'Configure Dibsy to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/Dibsy.svg',
      status: false,
      paymentMethod: 4,
    },
    {
      name: 'Flex Pay',
      description: 'Configure Flex-pay to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/flexpay.png',
      status: false,
      paymentMethod: 5,
    },
    {
      name: 'HyperPay',
      description: 'Configure HyperPay to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/cropped-011.png',
      status: false,
      paymentMethod: 8,
    },
    {
      name: 'DPO',
      description: 'Configure DPO to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/DPO-Group-Logo.svg',
      status: false,
      paymentMethod: 9,
    },
    {
      name: 'My Fatoorah',
      description: 'Configure Fatoorah to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/fatoora.png',
      status: false,
      paymentMethod: 10,
    },
    {
      name: 'MPesa',
      description: 'Configure MPaisa to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/mpesa.svg.png',
      status: false,
      paymentMethod: 11,
    },
    {
      name: 'Maxicash',
      description: 'Configure Maxicash to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/maxi_cash.jpg',
      status: false,
      paymentMethod: 13,
    },
    {
      name: 'payU',
      description: 'Configure payU to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/payU.jpg',
      status: false,
      paymentMethod: 14,
    },
    {
      name: 'PayStack',
      description: 'Configure PayStack to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/Paystack_Logo.png',
      status: false,
      paymentMethod: 15,
    },
    {
      name: 'PayToCheckout',
      description:
        'Configure PayToCheckout to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/paytocheckout.png',
      status: false,
      paymentMethod: 16,
    },
    {
      name: 'DirectPay',
      description:
        'Configure DirectPay to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/directpay.png',
      status: false,
      paymentMethod: 17,
    },
    {
      name: 'ChipAsia',
      description: 'Configure ChipAsia to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/Chip-logo.png',
      status: false,
      paymentMethod: 18,
    },
    {
      name: 'Moyasar',
      description: 'Configure Moyasar to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/moyasar.png',
      status: false,
      paymentMethod: 19,
    },
    {
      name: 'Payaid',
      description: 'Configure Payaid to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/payid.png',
      status: false,
      paymentMethod: 20,
    },
    {
      name: 'PayFast',
      description: 'Configure PayFast to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/Payfast.svg',
      status: false,
      paymentMethod: 21,
    },
    {
      name: 'Yoco',
      description: 'Configure Yoco to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/yoco.png',
      status: false,
      paymentMethod: 22,
    },
    {
      name: 'Cinet',
      description: 'Configure Cinet to accept payments from your customers.',
      paid: 'Free',
      source: 'assets/images/logo-cinetpay.webp',
      status: false,
      paymentMethod: 23,
    },
    {
      name: "Wave",
      description: "Configure Cinet to accept payments from your customers.",
      paid: "Free",
      source: "assets/images/wave-logo.png",
      status: false,
      paymentMethod: 24,
    },
    {
      name: "We-Generate",
      description: "Configure we-generate to accept payments from your customers.",
      paid: "Free",
      source: "assets/images/wegenerate.png",
      status: false,
      paymentMethod: 25,
    },
  ];
  featureSmsDetail = [
    {
      name: 'Twilo',
      description: 'Configure twilo to send sms to your customers.',
      paid: 'Free',
      source: 'assets/images/twilio.svg',
      status: false,
      smsMethod: 1,
    },
    {
      name: 'Sinch',
      description: 'Configure Sinch to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/Sinch.svg',
      status: false,
      smsMethod: 2,
    },
    {
      name: 'Sms Global',
      description: 'Configure Sms Global to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/smsglobal.png',
      status: false,
      smsMethod: 3,
    },
    {
      name: 'Two Factor',
      description: 'Configure Two Factor to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/twofactor.png',
      status: false,
      smsMethod: 4,
    },
    {
      name: 'Smart SMS',
      description: 'Configure Smart SMS to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/smart pay1.jpg',
      status: false,
      smsMethod: 5,
    },
    {
      name: 'SMS Bazar',
      description: 'Configure SMS Bazar to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/smsbazar.png',
      status: false,
      smsMethod: 6,
    },
    {
      name: 'SMS Country',
      description: 'Configure SMS Country to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/sms_country.png',
      status: false,
      smsMethod: 7,
    },
    {
      name: 'SMS To SMS',
      description: 'Configure SMS To SMS to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/logo_Sms_to.svg',
      status: false,
      smsMethod: 8,
    },
    {
      name: 'HUTCH',
      description: 'Configure Hutch to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/login_logo1.png',
      status: false,
      smsMethod: 9,
    },
    {
      name: 'MSG91',
      description: 'Configure MSG91 to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/msg91.jpg',
      status: false,
      smsMethod: 10,
    },
    {
      name: 'SendChamp',
      description: 'Configure SendChamp to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/sendchamp.png',
      status: false,
      smsMethod: 11,
    },
    {
      name: 'EXOTEL',
      description: 'Configure Exotel to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/Exotel_Final_logo_Blue_op-1.png',
      status: false,
      smsMethod: 12,
    },
    {
      name: 'MESSENTE',
      description: 'Configure Messente to send sms from your customers.',
      paid: 'Free',
      source: 'assets/images/messente.svg',
      status: false,
      smsMethod: 13,
    },
    {
      name: 'BulkSMS',
      description: 'Configure Messente to send BulkSMS from your customers.',
      paid: 'Free',
      source: 'assets/images/bulksms-logo.png',
      status: false,
      smsMethod: 14,
    },
    {
      name: "ETHIOPIA",
      description: "Configure ETHIOPIA to send ETHIOPIA from your customers.",
      paid: "Free",
      source: "assets/images/ETHIOPIA.png",
      status: false,
      smsMethod: 15,
    },
    {
      name: "SMS MISR",
      description: "Configure SMS MISR to send SMS MISR from your customers.",
      paid: "Free",
      source: "assets/images/SMSMISR.jpg",
      status: false,
      smsMethod: 16,
    },
    {
      name: "SMS GEEZ",
      description: "Configure SMS GEEZ to send SMS GEEZ from your customers.",
      paid: "Free",
      source: "assets/images/gezz.png",
      status: false,
      smsMethod: 17,
    },
    {
      name: "SMS MASAFA",
      description: "Configure SMS MASAFA  to send SMS MASAFA  from your customers.",
      paid: "Free",
      source: "assets/images/MASAFA.png",
      status: false,
      smsMethod: 18,
    },
    {
      name: "SMS MOBILESASA",
      description: "Configure SMS MOBILESASA  to send SMS MOBILESASA  from your customers.",
      paid: "Free",
      source: "assets/images/mobilesasa.png",
      status: false,
      smsMethod: 19,
    },
  ];
  thirdParty = [
    {
      name: 'Fcm Server Key',
      description: 'Configure Server Key for Push Notification.',
      paid: 'Free',
      source: '',
      status: false,
      key: 'fcmKey',
      thirdPartyMethod: 1,
    },
    {
      name: 'Zendesk',
      description: 'Configure Zendesk to send message from your customers.',
      paid: 'Free',
      source: '',
      status: false,
      key: 'isZendeskEnabled',
      thirdPartyMethod: 9,
    },
  ];
  emailGateway = [
    {
      name: 'SendGrid',
      description: 'Configure SendGrid to send Email to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      emailMethod: 1,
    },
    {
      name: 'MailChimp',
      description: 'Configure MailChimp to send Email to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      emailMethod: 3,
    },
    {
      name: 'Node Mailer',
      description: 'Configure Node Mailer to send Email to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      emailMethod: 4,
    },
    {
      name: 'G-Suite',
      description: 'Configure G-Suite to send Email to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      emailMethod: 2,
    },
  ];
  callingGateway = [
    {
      name: 'Exotel',
      description: 'Configure Exotel to send Call to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      callMaskingMethod: 1,
    },
    {
      name: 'Twillio',
      description: 'Configure Twillio to send Call to your customers.',
      paid: 'Free',
      source: '',
      status: false,
      callMaskingMethod: 2,
    },
  ];
  feature = [
    {
      title: 'Stripe',
      paymentMethod: 1,
      keys: [
        {
          keyTitle: 'SECRET_KEY',
          placeholder: 'Enter the Secret_key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PUBLIC_KEY',
          placeholder: 'Enter the public_key',
          required: true,
          type: 'string',
        },
        {
          Title: 'Stripe Connect',
          keyTitle: 'isConnectEnabled',
          placeholder: 'Select Stripe Connect',
          type: 'select',
          required: false,
          selectValue: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
        // {
        //   Title: "Connect Type",
        //   keyTitle: "connectMethodType",
        //   placeholder: "Select Connect Type",
        //   type: "select",
        //   required: false,
        //   selectValue:[
        //     {label:'Automatic',value:"automatic"},
        //     {label:'Manual',value:"manual"},
        //   ]
        // },
        {
          Title: 'Payment Method',
          keyTitle: 'paymentMethodType',
          placeholder: 'Select Payment Type',
          type: 'select',
          required: false,
          selectValue: [
            { label: 'Automatic', value: 'auto' },
            { label: 'Manual', value: 'manual' },
          ],
        },
        {
          Title: 'Gateway Tax Paid By',
          keyTitle: 'gatewayTaxPayBy',
          placeholder: 'Select GateWay Tax Payee',
          type: 'select',
          required: false,
          selectValue: [
            { label: 'Admin', value: 'admin' },
            { label: 'Merchant', value: 'merchant' },
          ],
        },
        {
          Title: 'Payment Frequency',
          keyTitle: 'paymentFrequency',
          placeholder: 'Select Payment Frequency',
          type: 'select',
          required: false,
          selectValue: [
            { label: 'Monthly', value: 'monthly' },
            { label: 'Weekly', value: 'weekly' },
          ],
        },
        {
          keyTitle: 'dayOfPay',
          placeholder: 'Enter the Day Of Pay',
          required: true,
          type: 'string',
        },
        {
          Title: 'Google Pay',
          keyTitle: 'isGooglePayEnabled',
          placeholder: 'Enter the public_key',
          type: 'boolean',
          required: false,
        },
        {
          Title: 'Apple Pay',
          keyTitle: 'isApplePayEnabled',
          placeholder: 'Enter the public_key',
          type: 'boolean',
          required: false,
        },
      ],
    },
    {
      title: 'RazorPay',
      paymentMethod: 2,
      keys: [
        {
          keyTitle: 'KEY_ID',
          placeholder: 'Enter the key_id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'KEY_SECRET',
          placeholder: 'Enter the key_secret',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'GreenPay',
      paymentMethod: 3,
      keys: [
        {
          keyTitle: 'GREEN_SECRET_KEY',
          placeholder: 'Enter the Green_secret_key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'TERMINAL_ID',
          placeholder: 'Enter the Terminal_id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MERCHANT_ID',
          placeholder: 'Enter the Merchant_id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MERCHANT_ENDPOINT',
          placeholder: 'Enter the Merchant_Endpoint',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Dibsy',
      paymentMethod: 4,
      keys: [
        {
          keyTitle: 'DIBSY_SECRET_KEY',
          placeholder: 'Enter the Dibsy_secret_key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'URL',
          placeholder: 'Enter the Url',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Flex Pay',
      paymentMethod: 5,
      keys: [
        {
          keyTitle: 'FLEX_PAY_SECRET_KEY',
          placeholder: 'Enter the Flex pay secret key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'FLEX_MERCHANT',
          placeholder: 'Enter the Flex pay merchant key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'HyperPay',
      paymentMethod: 8,
      keys: [
        {
          keyTitle: 'HYPER_PAY_ENTITY_ID',
          placeholder: 'Enter the Hyper Pay Entity id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HYPER_PAY_TOKEN',
          placeholder: 'Enter the Hyper Pay Token key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HYPER_PAY_TEST_MODE',
          placeholder: 'Enter the HYPER_PAY_TEST_MODE',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HYPER_PAY_HOST',
          placeholder: 'Enter the HYPER_PAY_HOST',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'DPO',
      paymentMethod: 9,
      keys: [
        {
          keyTitle: 'DPO_COMPANY_TOKEN',
          placeholder: 'Enter the Company Token',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'DPO_COMPANY_REF',
          placeholder: 'Enter the Company Reference',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'DPO_COMPANY_SERVICETYPE',
          placeholder: 'Enter the Company Service Type',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'My Fatoorah',
      paymentMethod: 10,
      keys: [
        {
          keyTitle: 'MYFATOORAH_TOKEN',
          placeholder: 'Enter the Fatoorah token',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'MPesa',
      paymentMethod: 11,
      keys: [
        {
          keyTitle: 'MPESA_CONSUMER_KEY',
          placeholder: 'Enter the consumer key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MPESA_CONSUMER_SECRET',
          placeholder: 'Enter the consumer secret key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MPESA_MID',
          placeholder: 'Enter the Mpesa mid key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MPESA_PASSKEY',
          placeholder: 'Enter the Mpesa passkey key',
          required: true,
          type: 'string',
        },
        {
          Title: 'MPesa Connect',
          keyTitle: 'isConnectEnabled',
          placeholder: 'Select Mpesa Connect',
          type: 'select',
          required: false,
          selectValue: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ],
        },
      ],
    },
    {
      title: 'Maxicash',
      paymentMethod: 13,
      keys: [
        {
          keyTitle: 'MAXICASH_MERCHANT_ID',
          placeholder: 'Enter the merchant Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MAXICASH_MERCHANT_PASSWORD',
          placeholder: 'Enter the maxicash merchant password',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'payU',
      paymentMethod: 14,
      keys: [
        {
          keyTitle: 'PAYU_MERCHANT_SALT',
          placeholder: 'Enter the PayU merchant salt',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYU_MERCHANT_KEY',
          placeholder: 'Enter the payU merchant password',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'PayStack',
      paymentMethod: 15,
      keys: [
        {
          keyTitle: 'PAYSTACK_SECRETKEY',
          placeholder: 'Enter the PayStack secret key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYSTACK_PUBLICKEY',
          placeholder: 'Enter the PayStack Public Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'PayToCheckout',
      paymentMethod: 16,
      keys: [
        {
          keyTitle: 'twoCheckoutMerchantId',
          placeholder: 'Enter the two checkout merchant id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'twoCheckoutSecretKey',
          placeholder: 'Enter the two checkout secret Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'DirectPay',
      paymentMethod: 17,
      keys: [
        {
          keyTitle: 'DIRECTPAY_MERCHANTID',
          placeholder: 'Enter the DirectPay Merchant id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'DIRECTPAY_APIKEY',
          placeholder: 'Enter the DirectPay Api Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'ChipAsia',
      paymentMethod: 18,
      keys: [
        {
          keyTitle: 'CHIPASIA_URL',
          placeholder: 'Enter the URL',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'CHIPASIA_BRANDID',
          placeholder: 'Enter the Chip Asia Brand Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'CHIPASIA_APIKEY',
          placeholder: 'Enter the Chip Asia Api Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Moyasar',
      paymentMethod: 19,
      keys: [
        {
          keyTitle: 'MOYASAR_PUBLISHABLE_KEY',
          placeholder: 'Enter the URL',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MOYASAR_PAYMENT_URL',
          placeholder: 'Enter the Moyasar payment url',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MOYASAR_SECRET_KEY',
          placeholder: 'Enter the Moyasar secret key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Payaid',
      paymentMethod: 20,
      keys: [
        {
          keyTitle: 'PAYAID_API_KEY',
          placeholder: 'Enter the URL',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYAID_SALT',
          placeholder: 'Enter the PAYAID_SALT',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYAID_URL',
          placeholder: 'Enter the Payaid URL ',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYAID_MODE',
          example: '(Ex:LIVE/TEST)',
          placeholder: 'Enter the Payaid Mode ',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'PayFast',
      paymentMethod: 21,
      keys: [
        {
          keyTitle: 'PAYFAST_MERCHANT_ID',
          placeholder: 'Enter the Merchant Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYFAST_MERCHANT_KEY',
          placeholder: 'Enter the Merchant Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYFAST_MERCHANT_PASSPHRASE',
          placeholder: 'Enter the Merchant PassPhrase ',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'PAYFAST_PAYMENT_URL',
          placeholder: 'Enter the Payment Url',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Yoco',
      paymentMethod: 22,
      keys: [
        {
          keyTitle: 'YOCO_SECRET_KEY',
          placeholder: 'Enter the Secret Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'YOCO_URL',
          placeholder: 'Enter the URL Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Cinet',
      paymentMethod: 23,
      keys: [
        {
          keyTitle: 'cinetSiteId',
          placeholder: 'Enter the cinetSiteId',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'cinetApIKey',
          placeholder: 'Enter the cinetApIKey',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'cinetUrl',
          placeholder: 'Enter the cinetUrl',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: "Wave",
      paymentMethod: 24,
      keys: [
        {
          keyTitle: "WAVE_APIKEY",
          placeholder: "Enter the WAVE API KEY",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: "We-Generate",
      paymentMethod: 25,
      keys: [
        {
          keyTitle: "WE_GENERATE_APPID",
          placeholder: "Enter the we-generate app id",
          required: true,
          type: "string",
        },
        {
          keyTitle: "WE_GENERATE_API_KEY",
          placeholder: "Enter the we-generate api key",
          required: true,
          type: "string",
        },
        {
          keyTitle: "WE_GENERATE_PAYMENT_URL",
          placeholder: "Enter the we-generate payment url",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: 'Twilo',
      smsMethod: 1,
      keys: [
        {
          keyTitle: 'TWILO_SENDER_NUMBER',
          placeholder: 'Enter the Twilio_sender_number',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'TWILO_ACCOUNT_SID',
          placeholder: 'Enter the Twilio_account_number',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'TWILO_AUTH_TOKEN',
          placeholder: 'Enter the Twilio_auth_token',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Sinch',
      smsMethod: 2,
      keys: [
        {
          keyTitle: 'SINCH_FROM_NUMBER',
          placeholder: 'Enter the Sinch number',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SINCH_REST_API_URL',
          placeholder: 'Enter the api_Url',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SINCH_API_TOKEN',
          placeholder: 'Enter the api_token',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Sms Global',
      smsMethod: 3,
      keys: [
        {
          keyTitle: 'SMS_GLOBAL_KEY',
          placeholder: 'Enter the Sms_global_key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_GLOBAL_SECRET',
          placeholder: 'Enter the sms_secret',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Two Factor',
      smsMethod: 4,
      keys: [
        {
          keyTitle: 'TWO_FACTOR_API_KEY',
          placeholder: 'Enter the api_key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Smart SMS',
      smsMethod: 5,
      keys: [
        {
          keyTitle: 'SMART_SMS_USER_NAME',
          placeholder: 'Enter the user_name',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMART_SMS_PASSWORD',
          placeholder: 'Enter the sms_password',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMART_SMS_SENDER_ID',
          placeholder: 'Enter the sender Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMART_SMS_URL',
          placeholder: 'Enter the sms_url',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'SMS Bazar',
      smsMethod: 6,
      keys: [
        {
          keyTitle: 'SMS_BAZAR_USER_NAME',
          placeholder: 'Enter the user_name',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_BAZAR_PASSWORD',
          placeholder: 'Enter the password',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_BAZAR_DLT_TEMPLATE',
          placeholder: 'Enter the dlt_template',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_BAZAR_FROM',
          placeholder: 'Enter the sms_bazar_form',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_BAZAR_PEID',
          placeholder: 'Enter the sms_bazar_peid',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'SMS Country',
      smsMethod: 7,
      keys: [
        {
          keyTitle: 'SMS_COUNTRY_USERNAME',
          placeholder: 'Enter the user_name',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_COUNTRY_PASSWORD',
          placeholder: 'Enter the password',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SMS_COUNTRY_SENDERID',
          placeholder: 'Enter the sender_id',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'SMS To SMS',
      smsMethod: 8,
      keys: [
        {
          keyTitle: 'SMSTO_TOKEN',
          placeholder: 'Enter the token',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'HUTCH',
      smsMethod: 9,
      keys: [
        {
          keyTitle: 'HUTCH_CAMPAIGN_NAME',
          placeholder: 'Enter the Campaign Name',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HUTCH_MASK',
          placeholder: 'Enter the Mask Name',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HUTCH_TOKEN',
          placeholder: 'Enter the Hutch token',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'HUTCH_URL',
          placeholder: 'Enter the Url',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'MSG91',
      smsMethod: 10,
      keys: [
        {
          keyTitle: 'MSG91_FLOW_ID',
          placeholder: 'Enter the MSG91_FLOW_ID',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MSG91_SENDER_ID',
          placeholder: 'Enter the  MSG91_SENDER_ID',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MSG91_AUTHKEY',
          placeholder: 'Enter the MSG91 AUTHKEY',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MSG91_URL',
          placeholder: 'Enter the MSG91_URL',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'SendChamp',
      smsMethod: 11,
      keys: [
        {
          keyTitle: 'SENDCHAMP_URL',
          placeholder: 'Enter the SENDCHAMP_URL',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SENDCHAMP_AUTHKEY',
          placeholder: 'Enter the  SENDCHAMP_AUTHKEY',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SENDCHAMP_NAME',
          placeholder: 'Enter the SENDCHAMP_NAME',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'EXOTEL',
      smsMethod: 12,
      keys: [
        {
          keyTitle: 'EXOTEL_API_KEY',
          placeholder: 'Enter the EXOTeL_API_KEY',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_API_TOKEN',
          placeholder: 'Enter the  EXOTEL_API_TOKEN',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_SUB_DOMAIN',
          placeholder: 'Enter the EXOTEL_SUB_DOMAIN',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_SID',
          placeholder: 'Enter the EXOTEL_SID',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTELE_TEMPLATE',
          placeholder: 'Enter the EXOTELE_TEMPLATE',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_FROM',
          placeholder: 'Enter the EXOTEL_FROM',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'MESSENTE',
      smsMethod: 13,
      keys: [
        {
          keyTitle: 'MESSENTE_USERNAME',
          placeholder: 'Enter the MESSENTE_USERNAME',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MESSENTE_PASSWORD',
          placeholder: 'Enter the MESSENTE_PASSWORD',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'MESSENTE_SENDER',
          placeholder: 'Enter the MESSENTE_SENDER',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'BulkSMS',
      smsMethod: 14,
      keys: [
        {
          keyTitle: 'BULK_SMS_USERNAME',
          placeholder: 'Enter the BULK_SMS_USERNAME',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'BULK_SMS_PASSWORD',
          placeholder: 'Enter the BULK_SMS_PASSWORD',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'BULK_SMS_API_URL',
          placeholder: 'Enter the BULK_SMS_API_URL',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: "ETHIOPIA",
      smsMethod: 15,
      keys: [
        {
          keyTitle: "ETHIOPIA_USERNAME",
          placeholder: "Enter the ETHIOPIA_USERNAME",
          required: true,
          type: "string",
        },
        {
          keyTitle: "ETHIOPIA_PASSWORD",
          placeholder: "Enter the ETHIOPIA_PASSWORD",
          required: true,
          type: "string",
        },
        {
          keyTitle: "ETHIOPIA_API_URL",
          placeholder: "Enter the ETHIOPIA_API_URL",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: "SMS MISR",
      smsMethod: 16,
      keys: [
        {
          keyTitle: "SMS_MISR_USERNAME",
          placeholder: "Enter the SMS_MISR_USERNAME",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MISR_PASSWORD",
          placeholder: "Enter the SMS_MISR_PASSWORD",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MISR_SENDER_ID",
          placeholder: "Enter the SMS_MISR_SENDER_ID",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MISR_URL",
          placeholder: "Enter the SMS_MISR_URL",
          required: true,
          type: "string",
        },
        {
          Title: "SMS_MISR_ENVIROMENT",
          keyTitle: "SMS_MISR_ENVIROMENT",
          placeholder: "Select",
          type: "select",
          required: true,
          selectValue: [
            { label: 'Live', value: 1 },
            { label: 'Test', value: 2 },
          ]
        }
      ],
    },
    {
      title: "SMS GEEZ",
      smsMethod: 17,
      keys: [
        {
          keyTitle: "SMS_GEEZ_APIKEY",
          placeholder: "Enter the SMS_GEEZ_APIKEY",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_GEEZ_SHORTCODEID",
          placeholder: "Enter the SMS_GEEZ_SHORTCODEID",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_GEEZ_URL",
          placeholder: "Enter the SMS_GEEZ_URL",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: "SMS MASAFA",
      smsMethod: 18,
      keys: [
        {
          keyTitle: "SMS_MASAFA_USERNAME",
          placeholder: "Enter the SMS_MASAFA_USERNAME",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MASAFA_PASSWORD",
          placeholder: "Enter the SMS_MASAFA_PASSWORD",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MASAFA_SENDERID",
          placeholder: "Enter the SMS_MASAFA_SENDERID",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MASAFA_URL",
          placeholder: "Enter the SMS_MASAFA_URL",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: "SMS MOBILESASA",
      smsMethod: 19,
      keys: [
        {
          keyTitle: "SMS_MOBILESASA_TOKEN",
          placeholder: "Enter the SMS_MOBILESASA_TOKEN",
          required: true,
          type: "string",
        },
        {
          keyTitle: "SMS_MOBILESASA_SENDERID",
          placeholder: "Enter the SMS_MOBILESASA_SENDERID",
          required: true,
          type: "string",
        }
      ],
    },
    {
      title: 'Fcm Server Key',
      thirdPartyMethod: 1,
      keys: [
        {
          keyTitle: 'fcmKey',
          placeholder: 'Enter the FCM Server Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Zendesk',
      thirdPartyMethod: 9,
      keys: [
        {
          keyTitle: 'zendeskUrl',
          placeholder: 'Enter the zendesk Url',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'zendeskApplicationId',
          placeholder: 'Enter the zendesk Application Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'zendeskOAuthClientId',
          placeholder: 'Enter the zendesk OAuth Client Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'zendeskAccountKey',
          placeholder: 'Enter the zendesk Account Key',
          required: true,
          type: 'string',
        },
      ],
    },

    {
      title: 'ShipRocket',
      thirdPartyMethod: 11,
      keys: [
        {
          keyTitle: 'shipRoketEmail',
          placeholder: 'Enter the Ship Rocket Email',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'shipRoketPasswoard',
          placeholder: 'Enter the Ship Rocket Password',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'SendGrid',
      emailMethod: 1,
      keys: [
        {
          keyTitle: 'SENDGRIDFROMEMAIL',
          placeholder: 'Enter the Send Grid Email',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'SENDGRIDKEY',
          placeholder: 'Enter the Send Grid Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'MailChimp',
      emailMethod: 3,
      keys: [
        {
          keyTitle: 'mailchimpKey',
          placeholder: 'Enter the Mail Chimp Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'mailchimpFromMail',
          placeholder: 'Enter the Mail Chimp From Mail',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Node Mailer',
      emailMethod: 4,
      keys: [
        {
          keyTitle: 'senderTitle',
          placeholder: 'Enter the Sender Title (optional)',
          required: false,
          type: 'string',
        },
        {
          keyTitle: 'NODE_MAILER_HOST',
          placeholder: 'Enter the Node Mailer Host',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'NODE_MAILER_PORT',
          placeholder: 'Enter the Node Mailer Port',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'NODE_MAILER_FROM_EMAIL',
          placeholder: 'Enter the Node Mailer From Email',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'NODE_MAILER_PASSWORD',
          placeholder: 'Enter the Node Mailer Password',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'NODE_MAILER_SERVICE',
          placeholder: 'Enter the Node Mailer Service',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'G-Suite',
      emailMethod: 2,
      keys: [
        {
          keyTitle: 'client_id',
          placeholder: 'Enter the client Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'project_id',
          placeholder: 'Enter the Project Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'client_secret',
          placeholder: 'Enter the Client Secret Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'access_token',
          placeholder: 'Enter the Access Token Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'refresh_token',
          placeholder: 'Enter the Refresh Token Key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'expiry_date',
          placeholder: 'Enter the Expiry Date Key',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Exotel',
      callMaskingMethod: 1,
      keys: [
        {
          keyTitle: 'EXOTEL_API_KEY',
          placeholder: 'Enter the Exotel Api key',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_API_TOKEN',
          placeholder: 'Enter the Exotel Api token',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_SUB_DOMAIN',
          placeholder: 'Enter the Exotel Sub Domain',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_SID',
          placeholder: 'Enter the Exotel Sid',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_CALLER_ID',
          placeholder: 'Enter the Exotel Caller Id',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'EXOTEL_CALLER_TYPE',
          placeholder: 'Enter the Exotel Caller Type',
          required: true,
          type: 'string',
        },
      ],
    },
    {
      title: 'Twillio',
      callMaskingMethod: 2,
      keys: [
        {
          keyTitle: 'TWILLIO_ACCOUNT_SID',
          placeholder: 'Enter the Twillio account sid',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'TWILLIO_AUTH_TOKEN',
          placeholder: 'Enter the Twillio Auth Token',
          required: true,
          type: 'string',
        },
        {
          keyTitle: 'TWILLIO_FROM_NUMBER',
          placeholder: 'Enter the Twillio From Number',
          required: true,
          type: 'string',
        },
      ],
    },
  ];

  constructor(private commonService: CommonService,private translate:TranslateService) {
    if (localStorage.getItem('settings')) {
      let settings = this.commonService.decryptLocalStorage('settings');      
      if (settings) {
        this.modules = settings.modules;
      }
    }
    this.modulesSetting();
  }

  modulesSetting() {
    this.MODULES.basic.map((module) => {
      if (module?.moduleCode == 1) {
        module['displayName'] = this.translate.instant('sideBar.dashboard');
      }
      if (module?.moduleCode == 2) {
        module['displayName'] = this.modules?.booking;
      }
      if (module?.moduleCode == 3) {
        module['displayName'] = this.modules?.customers;
      }
      if (module?.moduleCode == 4) {
        module['displayName'] = this.modules?.vehicle;
      }
      if (module?.moduleCode == 5) {
        module['displayName'] = this.modules?.driver;
      }
      if (module?.moduleCode == 6) {
        module['displayName'] = this.modules?.zone;
      }
      if (module?.moduleCode == 7) {
        module['displayName'] = this.modules?.teams;
      }
      if (module?.moduleCode == 8) {
        module['displayName'] = this.modules?.pricing;
      }
    });

    this.MODULES.settings.map((module) => {
      if (module?.moduleCode == 10.3) {
        module['displayName'] = this.modules?.booking + ' ' + 'Allocation';
      }
    });
  }
}
