import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-share-bookings',
  templateUrl: './share-bookings.component.html',
  styleUrls: ['./share-bookings.component.scss']
})
export class ShareBookingsComponent implements OnInit {
  url: string='';
  lang:string='';

  constructor(private commonService: CommonService,public dialogRef: MatDialogRef<ShareBookingsComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.lang = this.commonService.decryptLocalStorage('adminProfile')?.lang || 'en';
    
    if(this.data){
      this.url=environment.trackUrl+this.data+`?lang=${this.lang}`
    }

  
  }

}
