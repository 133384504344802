import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-choose-plans',
  templateUrl: './choose-plans.component.html',
  styleUrls: ['./choose-plans.component.scss'],
})
export class ChoosePlansComponent implements OnInit {
  slideConfigType1 = {
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 500,
  };
  appId = localStorage.getItem('appId');
  plans: any[] = [];
  url: any;
  appSettings: any;
  adminProfile: any;

  constructor(private translate:TranslateService, private https: HttpsService,private dialog:MatDialog,private commonService: CommonService,private router: Router) {
    this.appSettings = this.commonService.decryptLocalStorage('settings')
    this.adminProfile = this.commonService.decryptLocalStorage('adminProfile')
  }

  ngOnInit(): void {
    this.getAllPlan();
  }

  getAllPlan() {
    const body = {
      "isExtention": false,
      "moduleName": "",
      "appId": this.appId
    }
    this.https.httpPostWithHeader('getAllPlans', body, true).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.plans = res.data

      }
    })
  }

  purchasePlan(id: any, data: any) {
    let body = {
      priceId: id,
      url: environment.stripeRedirectionUrl,
      appId: this.appSettings.appId
    }
    this.https.httpPostWithHeader('subscribe', body, true).subscribe((res: any) => {
      if (res && res['statusCode'] == 200) {
        this.url = res['data'].url
        if (this.url) {
          window.open(this.url, "_self")
        }
      }
    })
  }


  btn_logOut() {
        this.dialog.closeAll();
        localStorage.clear();
        this.commonService.snackbarSuccess(this.translate.instant('logout_success'),'',2000);
        this.router.navigateByUrl('/auth/login');
      }
}
