import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BookingCommonService } from 'src/app/pages/bookings/booking-common.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Constant } from 'src/app/services/common/constant';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-booking-listings',
  templateUrl: './booking-listings.component.html',
  styleUrls: ['./booking-listings.component.scss']
})
export class BookingListingsComponent implements OnInit {

  jobsData: any;
  driverData: any=[];
  selected:any
  constructor(private https:HttpsService,private common:CommonService,private translate:TranslateService
    ,private bookingService:BookingCommonService,public constant: Constant, public dialogRef: MatDialogRef<BookingListingsComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.drivers();
  }

  drivers() {
    var param = 'login=true&driverType=ALL&bId='+this.data;
    this.https.httpGetWithHeaderParam('driver', param).subscribe(
      (res: any) => {
        this.driverData = res.data;
      },
      (error) => console.log(error)
    );
  }

  btn_add(){
    if(this.selected){
      let body={ id:this.data, driverId: this.selected };
      this.https.httpPostWithHeader('assignJob',body,true).subscribe((res:any)=>{
        if(res.statusCode == 200){
          this.dialogRef.close();
          this.common.snackbarSuccess(res['message'], '', 2000)
        }
      })
    }else{
      this.common.snackbarError(this.translate.instant('dispatcher_latest.driver_selection_warn'),'',2000);
    }
  }
}
