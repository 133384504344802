import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DailogServiceService } from 'src/app/dialogs/dailog-service.service';
import { CommonService } from '../../common/common.service';


@Injectable({
  providedIn: 'root'
})
export class GetInterceptorService implements HttpInterceptor {
  errorInBody = false;
  adminProfile: any;

  constructor(private commonService: CommonService,private router: Router,private dialog:MatDialog,private dialogs: DailogServiceService) { 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {  

      if (event instanceof HttpResponse) { 
        if(event.body.statusCode === 401) {
          this.errorInBody = true;
          this.commonService.snackbarError(event.body.message, '',2000);
        }
        else if(event.body.statusCode === 400) {
          this.errorInBody = true;
          this.commonService.snackbarError(event.body.message, '',2000);
        }
        else if(event.body.statusCode === 408) {
          this.errorInBody = true;
          this.commonService.snackbarError(event.body.message, '',2000);
        }
        else if(event.body.statusCode === 500) {
          this.errorInBody = true;
          this.commonService.snackbarError(event.body.message, '',2000);
        }
        else {
          this.errorInBody = false;
        }
      }
    }, (error: any) => {      
      if(!this.errorInBody) {
        if (error.status === 401) {
          this.dialog.closeAll();
          localStorage.clear();
          this.commonService.snackbarError('Session Expired', '',2000);
          this.router.navigateByUrl("/auth/login")
        }else if (error.status === 400) {
          this.commonService.snackbarError(error.error.message, '',2000);
        }else if (error.status === 402) {
          this.commonService.snackbarError(error.error.message, '',2000);
        }else if (error.status === 408) {
          this.commonService.snackbarError(error.error.message, '',2000);
        }else if (error.status === 500) {
          this.commonService.snackbarError(error.error.message, '',2000);
        }else if (error.status === 429) {
          this.commonService.snackbarError('Unable to access', '',2000);
        }
       else{
           this.commonService.snackbarError('SOMETHING WENT WRONG... TRY CHECKING YOUR NETWORK CONNECTIONS!', '',2000);
        }
      }
    }));
  } 
}
