<div class="cntnt_hd">
  <h3>Bookings</h3>
</div>
<div class="bkng_lstb_dtl tm_record mt-3 txt_fdx">
  <div class="bk_t_g">
    <ng-container *ngFor="let data of jobsData">

      <div class="bkng_dtl_inr">
        <div class="bkdtl_tp tm_rcd">
          <div class="bkd_lt">
            <h2>Order ID {{data?.jobId}}</h2>
            <p>{{data?.userId?.firstName+ " " + data?.userId?.lastName }}</p>
          </div>
          <div class="cmpltd_btsn">
            <button class="btn auto_assign crtd_btn">{{ constant.SHOW_BOOKING_STATUS[data?.status] }}</button>
          </div>
        </div>
        <div class="bkng_fd">
          <div class="lft">
            <h2><img src="assets/images/pickupp.svg" alt="" />{{ data?.bookingDate | date: "MMM d"}}</h2>
            <p>{{ data?.picAddress }}</p>
          </div>
        </div>
        <div class="bkng_fd tm_rcd">
          <div class="lft">
            <h2><img src="assets/images/drpicn.svg" alt="" />{{ data?.completedAt | date: "MMM d" }}</h2>
            <p>{{ data?.dropAddress }}</p>
          </div>
        </div>
      </div>

      <div class="ld_chkbx">
        <mat-checkbox class="example-margin" (change)="btn_selection($event.checked,data)"> </mat-checkbox>
      </div>
    </ng-container>
    <h6 class="data-not-fnd" *ngIf="jobsData && jobsData?.length === 0">
      <figure style="text-align: center">
        <img src="assets/images/nodata.png" alt="" />
      </figure>
    </h6>
  </div>
  <div class="pt-3 btn_asng" *ngIf="jobsData && jobsData?.length > 0">
    <button class="btn btn-primary" (click)="btn_add()">Assign</button>
    <button class="btn btn-primary scd_bt" mat-dialog-close="">Close</button>
  </div>
</div>