import { Injectable } from "@angular/core";
import { CommonService } from "./common/common.service";

@Injectable()
export class ScriptLoadService {
  address:any
  googleAPiKey:any
  private scriptLoaded = false;
  languageList: any;
  selectedLangValue: string='en';
  constructor(private common:CommonService) { }
  
  loadScript(key): Promise<any> {
    if (localStorage['languages']){
      this.languageList = this.common.decryptLocalStorage('languages');
      let languageList= this.languageList.filter(language => language.isSelected);
      this.selectedLangValue = languageList[0].value;
    }
    this.googleAPiKey=key;    
    return new Promise((resolve, reject) => {
      if (document.getElementById('GoogleApi')) {
        resolve(true);
      } else {
        const script = document.createElement('script');
        script.id = 'GoogleApi';
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=googleMapsLoaded&libraries=places,drawing,geometry,visualization&language=en&v=weekly`;
        script.onerror = reject;
        script.async =true;
        script.onload = () => {
          this.scriptLoaded = true;
          resolve(true);
        };
        script.onerror = () => {
          reject(new Error('Failed to load script'));
        };
        document.head.appendChild(script);
      }
    });
  }
}