<div class="sucs_bkng_otr adtm_sc adcsmt_otr">
      <div class="ad_sm">
        <div *ngIf="data">
            <figure>
                <img [src]="data" alt="">
            </figure>
        </div>
        <h6 class="data-not-fnd" *ngIf="!data">
          <figure style="text-align: center">
            <img src="assets/images/nodata.png" alt="" />
          </figure>
        </h6>
      <div class="dfdfffd">
        <button class="btn btn-primary" mat-dialog-close>Close</button>
      </div>
  </div>
  