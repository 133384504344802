import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { CommonService } from './services/common/common.service';

const SECRET_KEY: any = 'my secret key';

declare global {
  interface Window {
    googleMapsLoaded: () => void;
  }
}

if (typeof window !== 'undefined') {
  window.googleMapsLoaded = () => {};
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'app';
  textDirection: string;
  languageList: any;
  accessToken: string;
  setting: any;
  constructor(
    public updates: SwUpdate,
    public push: SwPush,
    private common: CommonService
  ) {
    this.serviceWorker();
  }

  ngOnInit() {
    this.accessToken = this.common.decryptLocalStorage('accessToken');
  }
  ngAfterViewInit(): void {
  }

  formatNumberWithArabic(number: number): string {
    const formattedNumber = number.toLocaleString('ar-EG');
    return formattedNumber;
  }

  serviceWorker() {
    navigator.serviceWorker.register('ngsw-worker.js');
    this.updates.available.subscribe((_) =>
      this.updates.activateUpdate().then(() => {
        document.location.reload();
      })
    );
  }
}
