<div class="wlk_wrp" (click)="close()">
    <figure><i class="fa-solid fa-xmark"></i></figure>
</div>
<div class="cst">
    <h3>{{title}}</h3>
    <form action="" [formGroup]="teamForm">
        <div class="ad_sm">
            <ng-container formArrayName="name_lng">
                <ng-container
                  *ngFor="let data of getType().controls; let index = index"
                  [formGroupName]="index"
                >
                  <div style="display: flex;">
                    <mat-label>{{modules?.teams}} {{'TEAMSPAGE.Name' | translate}}</mat-label>
                    <button
                      class="add-on-btn plus"
                      (click)="addMultiNameType()"
                      *ngIf="
                        index == 0 &&
                        teamForm.value.name_lng.length < languageList.length
                      "
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </button>
                    <button
                      class="add-on-btn plus"
                      (click)="removeStoreTypeType(index)"
                      *ngIf="index > 0"
                    >
                      <i class="fa fa-minus" aria-hidden="true"></i>
                    </button>
                  </div>
        
        
                  <mat-form-field class="example-full-width" floatLabel="never">
                    <input matInput placeholder="{{'TEAMSPAGE.Name' | translate}}" class="form_control" formControlName="value"
                        type="text" appNoSpaceAtFirst>
                </mat-form-field>
                  <div class="input-group pt-2" *ngIf="languageList && languageList.length>1">
                    <mat-select
                      class="form-control"
                      formControlName="lang"
                      placeholder="{{'dispatcher_latest.SelectLanguage'|translate}}"
                      (selectionChange)="onSelectionChangeType(index)"
                    >
                      <mat-option
                        *ngFor="let item of languageList"
                        [value]="item.value"
                        [disabled]="index > 0 ? btn_diabledType(item) : false"
                        >{{ item.language }}</mat-option
                      >
                    </mat-select>
                    <div *ngIf="data.controls.lang.errors" class="invalid-feedback">
                      <div *ngIf="data.controls.lang.errors.required">
                        {{'TEAMSPAGE.Language' | translate}} {{'TEAMSPAGE.isrequired' | translate}} 
                      </div>
                    </div>
                  </div>  
                  <mat-error
                  *ngIf="
                    submitted && data.controls['value'].hasError('required')
                  "
                  >  {{'TEAMSPAGE.Name' | translate}} {{'TEAMSPAGE.isrequired' | translate}}
                </mat-error>
                </ng-container>
              </ng-container>
        </div>
        <div class="ad_sm">
            <mat-label>{{modules?.zone}}</mat-label>
            <mat-select class="form_control" [placeholder]="modules?.zone" formControlName="zoneIds" multiple>
                <mat-option *ngFor="let data of zoneData" [value]="data?._id">
                  <ng-container *ngIf="data?.name_lng && data?.name_lng?.length>0;else name">
                    <span *ngFor="let data of data?.name_lng; let lastItem = last">
                      {{ data.value }}
                      <span *ngIf="!lastItem">/</span>
                      </span>
                  </ng-container>
                  <ng-template #name>
                    <td>{{ data?.name }}</td>
                  </ng-template>                
                </mat-option>
            </mat-select>
        </div>
        <div class="ad_sm">
            <mat-label>{{modules?.vehicle}}</mat-label>
            <mat-select class="form_control" [placeholder]="modules?.vehicle" formControlName="vehicleIds" multiple>
                <mat-option *ngFor="let item of vehicleData" [value]="item?._id">
                  <ng-container *ngIf="item?.type_lng && item?.type_lng?.length>0; else name">
                    <span *ngFor="let data of item?.type_lng; let lastItem = last">
                        {{ data.value }}
                        <span *ngIf="!lastItem">/</span>
                      </span>
                  </ng-container>
                  <ng-template #name>
                    {{ item?.type }}
                  </ng-template>
                </mat-option>
            </mat-select>
        </div>
        <div class="ad_sm">
            <mat-label>{{'TEAMSPAGE.ForPickupDelivery'|translate}}</mat-label>
            <mat-select class="form_control" (selectionChange)="changePickupDelivery(teamForm.get('isPackage').value )" placeholder="{{'TEAMSPAGE.ForPickupDelivery'|translate}}" formControlName="isPackage" >
                <mat-option [value]="true" >{{'TEAMSPAGE.Yes'|translate}}</mat-option>
                <mat-option [value]="false">{{'TEAMSPAGE.No'|translate}}</mat-option>
                
            </mat-select>
        </div>

        <ng-container *ngIf="teamForm.get('isPackage').value == true">
            <div class="otd">
                <div class="dmu">
                    <label>{{'TEAMSPAGE.DimensionsUnit' | translate}}</label>
                    <mat-select class="form_control" placeholder="{{'TEAMSPAGE.DimensionsUnit' | translate}}" formControlName="dimensionsUnit">
                        <mat-option *ngFor="let data of dimensionsUnitArray"
                            [value]="data?.value">{{data?.Name}}</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="submitted && teamForm.controls['dimensionsUnit'].hasError('required')">{{'TEAMSPAGE.DimensionsUnit' | translate}} {{'TEAMSPAGE.isrequired' | translate}}</mat-error>
                </div>
                <div class="dim">
                    <label>{{modules?.teams}} {{'TEAMSPAGE.Dimensions' | translate}}<sup>(Exp:10m*12m*50m)</sup></label>
                    <mat-form-field class="example-full-width" floatLabel="never">
                        <input matInput placeholder="{{'TEAMSPAGE.Dimensions' | translate}}" class="form_control"
                            formControlName="dimensions" type="text" appNoSpaceAtFirst>
                    </mat-form-field>
                    <mat-error *ngIf="submitted && teamForm.controls['dimensions'].hasError('required')">{{'TEAMSPAGE.Dimensions' | translate}} {{'TEAMSPAGE.isrequired' | translate}}</mat-error>
                </div>
            </div>
            <div class="otd">
                <div class="dmu">
                    <label>{{'TEAMSPAGE.WeightUnit' | translate}}</label>
                    <mat-select class="form_control" placeholder="{{'TEAMSPAGE.WeightUnit' | translate}}" formControlName="weightUnit">
                        <mat-option *ngFor="let data of weightUnitArray"
                            [value]="data?.value">{{data?.Name}}</mat-option>
                    </mat-select>
                </div>
                <div class="dim">
                    <label>{{modules?.teams}} {{'TEAMSPAGE.MaxWeight' | translate}}</label>
                    <mat-form-field class="example-full-width" floatLabel="never">
                        <input matInput placeholder="{{'TEAMSPAGE.MaxWeight' | translate}}" class="form_control"
                            formControlName="maxWeight" type="text" appNoSpaceAtFirst>
                    </mat-form-field>
                    <mat-error *ngIf="submitted && teamForm.controls['maxWeight'].hasError('required')">{{'TEAMSPAGE.MaxWeight' | translate}} {{'TEAMSPAGE.isrequired' | translate}}</mat-error>
                </div>
            </div>

            <div class="ad_sm">
                <label>{{'TEAMSPAGE.Image' | translate}}</label>
                <div class="pro_out">
                    <figure>
                        <img [src]="teamForm.value.image ? teamForm.value.image  : 'assets/images/gallery-add.png'"
                            alt="Icon">
                    </figure>
                    <span class="upld_pass"><input type="file" id="myfile" name="myfile"
                            (change)="onFileChange($event)"> <i class="fa fa-camera"></i></span>
                </div>
            </div>
        </ng-container>


    </form>
    <div class="pt-3 sb">
        <button class="btn btn-primary pt-3" (click)="btn_add()">{{'dispatcher_latest.Save'|translate}}</button>
    </div>

</div>