import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BookingCommonService {
  constructor() {}

  getNewFormatOfBooking(usersData) {  
    return new Promise((resolve, reject) => {
      if (usersData?.length > 0) {
        for (let i = 0; i < usersData.length; i++) {
          if (
            usersData[i] &&
            usersData[i].addresses &&
            usersData[i].addresses.length > 0
          ) {
            let pickArr = usersData[i].addresses.filter((val) => val.isPickup);
            let dropArr = usersData[i].addresses.filter((val) => !val.isPickup);
            usersData[i]['picAddress'] = this.getAddressFormat(pickArr[0]);
            usersData[i]['dropAddress'] = this.getAddressFormat(
              dropArr[dropArr.length - 1]
            );
          } else {
            usersData[i].pickup &&
              (usersData[i]['picAddress'] = this.getAddressFormat(
                usersData[i].pickup
              ));
            usersData[i].delivery &&
              (usersData[i]['dropAddress'] = this.getAddressFormat(
                usersData[i].delivery
              ));
          }
          if (i === usersData.length - 1) {
            return resolve(usersData);
          }
        }
      }
    });
  }

  getAddressFormat(data) {    
    if(data){
      if(data.area){
        return (
          data &&
          (data.government ? 'Govermental: ' + data.government + ',' : '') +
          ' ' +
          (data.area ? ' Area: ' + data.area + ',' : '') +
          ' ' +
          (data.block ? 'Block: ' + data.block + ',' : '') +
          ' ' +
          (data.street ? 'Street: ' + data.street + ',' : '') +
          ' ' +
          (data.jaddah ? 'Jaddah: ' + data.jaddah + ',' : '') +
          ' ' +
          (data.building ? 'Building: ' + data.building + ',' : '') +
          ' ' +
          (data.floorNumber ? 'Floor No. ' + data.floorNumber + ',' : '') +
          ' ' +
          (data.houseNo ? 'House No: ' + data.houseNo + ',' : '') +
          ' ' +
          (data.phone ? 'Phone: ' + data.phone + ',' : '')
        );
      }else{
        return (
          data &&
          (data.address ? 'Address: ' + data.address + ',' : '') +
          ' '+
          (data.area ? ' Area: ' + data.area + ',' : '')
        );
      }
    }
  }
}
