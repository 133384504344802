import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from '../../../services/role/role.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private roleService: RoleService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot):| Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((res) => {
      this.roleService.getProfile().then((data: any) => {
        if (data) { 
          var arr = this.roleService.sortedModules.map(
            (mod) => mod.route.split('/')[1]
          );
          if(state.url.split('/')[1] == 'introduction'){
            res(true);
          }else{
            if (arr.includes(state.url.split('/')[1])) {
              res(true);
            } else {
              res(false);
              this.router.navigateByUrl(
                this.roleService.defaultUrl ? this.roleService.defaultUrl : '/'
              );
            }
          }
        }
      });
    });
  }
}
