<div class="main_df">
    <h5>{{'BOOKINGDETAILS.Proof'|translate}}</h5>
    <div class="bkdt_csmr_lt">
        <!-- <label>Proof of Delivery</label> -->
        <div class="csmr_lt_inr" >
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of data" class="slide">
              <div class="prdt_img">
                <figure><img src="{{ slide }}" alt="Image" /></figure>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
        <div class="csmr_lt_inr" *ngIf="data && data?.length == 0">
          {{'BOOKINGDETAILS.NDF'|translate}}
        </div>
      </div>
</div>
