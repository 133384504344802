import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { apiList } from './api-list';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class HttpsService {
  baseUrl: string = environment.baseUrl;
  socket: any;
  iv = CryptoJS.enc.Base64.parse(environment.iv); //giving empty initialization vector
  key = CryptoJS.SHA256(environment.key); //hashing the key using SHA256
  encryptedString: any;
  webHooks: string = environment.webhookUrl;
  version: string;
  constructor(
    private http: HttpClient,
    private apiList: apiList,
    private common: CommonService
  ) { 
    this.version =localStorage.getItem('version') ? localStorage.getItem('version') :'';
   this.common.getVersion().subscribe((res:any)=>{
    if(res){
      this.version=res;
    }
   })
  }


  updateString(string) {
    this.version =localStorage.getItem('version') ? localStorage.getItem('version') :'';
    if(localStorage.getItem('version')){
      const parts = string.split('/');
      parts[5]=this.version;
      string = parts.join('/');
      return string;
    }else{
      return string;
    }
  }


  httpPost(url: string, params): Observable<Object> {
    let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.post(urlName, this.encryptData(params));
  }

  getCountryList() {
    return this.http.get<Response>("assets/file/countryCode.json").pipe(map(response => response));
  }

  getCountryTimeZone() {
    return this.http.get<Response>("assets/file/countrytimezone.json").pipe(map(response => response));
  }

  getCurrency() {
    return this.http.get<Response>("assets/file/currency.json").pipe(map(response => response));
  }

  httpPutWithHeader(url: string, id, params: any): Observable<Object> {
    let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.put(
      urlName + '/' + id,
      this.encryptData(params)
    );
  }
  httpPutWithQuery(url: string, id, params: any): Observable<Object> {
    let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.put(
      urlName + '?' + id,
      this.encryptData(params)
    );
  }

  httpPutWithHeaderBody(
    url: string,
    params: any,
    isFormData
  ): Observable<Object> {
    let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.put(
      urlName,
      this.encryptData(params)
    );
  }

  httpPostWithHeaderWebHooks(url: string, params: any, isFormData): Observable<Object> {
   let urlName= this.webHooks + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.post(
      urlName,
      this.encryptData(params),
      this.header(isFormData)
    );
  }

  httpGetWithHeaderWebHooks(url: string): Observable<Object> {
   let urlName= this.webHooks + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(urlName, this.header(false));
  }

  httpPutWithHeaderParamAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.put(
      urlName + '/' + id + '?' + this.encryptData(params),
      {}
    );
  }

  httpPatchWithHeaderParamAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.patch(
      urlName + '/' + id + '?' + params,
      {}
    );
  }
  httpDeleteWithHeaderAndIdWebHooks(url: string, id, isFormData): Observable<Object> {
   let urlName= this.webHooks + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.delete(
      urlName + '/' + id,
      this.header(isFormData)
    );
  }

  httpPutWithHeaderBodyAndId(
    url: string,
    id,
    params: any,
    isFormData
  ): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.put(
      urlName + '/' + id,
      this.encryptData(params)
    );
  }

  httpDeleteWithHeaderAndId(url: string, id, isFormData?): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.delete(
      urlName + '/' + id
    );
  }

  httpDeleteWithHeader(url: string, param, isFormData): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.delete(
      urlName + '?' + param
    );
  }

  httpPostWithHeader(url: string, params: any, isFormData): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.post(
      urlName,
      this.encryptData(params)
    );
  }

  httpPostWithHeaderId(url: string, id, params: any): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.post(
      urlName + '/' + id,
      this.encryptData(params)
    );
  }


  httpPostWithHeaderParam(url: string, query, params: any): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.post(
      urlName + '?' + query,
      this.encryptData(params)
    );
  }

  httpGetWithHeaderParam(url: string, params): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(
      urlName + '?' + params
    );
  }
  httpGetWithHeaderParamAndId(url: string, id, params): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(
      urlName + '/' + id + '?' + params
    );
  }

  httpGetWithHeader(url: string): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(urlName);
  }

  httpGetWithHeaderInital(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  httpGet(url: string): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(urlName);
  }

  httpGetWithoutHeader(url: string, id: string): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(urlName + '/' + id);
  }


  httpGetHeaderById(url: string, id: string): Observable<Object> {
   let urlName= this.baseUrl + this.apiList[url]
    urlName=this.updateString(urlName);
    return this.http.get(
      urlName + '/' + id
    );
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append('file', fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: 'multipart/form-data',
      authorization: localStorage['accessToken'],
    });
    const headersObj = { headers };
   let url= this.baseUrl + this.apiList['vUpload']
     url=this.updateString(url);
    return this.http.post(
      url,
      formData,
      headersObj
    );
  }

  header(isFormData) {
    if (
      localStorage['accessToken'] != undefined ||
      localStorage['accessToken'] != null
    ) {

      var headers;
      if (isFormData) {
        headers = new HttpHeaders({
          mimeType: 'multipart/form-data',
          authorization: localStorage['accessToken'],
        });
      } else {
        headers = new HttpHeaders({
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          // "mimeType": "multipart/form-data",
          authorization: localStorage['accessToken'],
        });
      }
      let option = {
        headers,
      };

      // option['observe'] = 'response';
      return option;
    } else {
      return null;
    }
  }

  encryptData(data) {
    const currentTimestamp = dayjs().valueOf();
    data['date'] = currentTimestamp;
    this.encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return {
      hash: this.encryptedString.toString(),
    };
  }

  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

