import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CommonService } from '../common/common.service';
import { Constant } from '../common/constant';
import { HttpsService } from '../http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { delay, skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService implements OnDestroy {
  profileData: any;
  sortedModules: any = [];
  defaultUrl: any;
  afterIntroductionUrl: any;
  modules: any;
  settings: any;
  textDirection: string;
  languageList: any[];
  flag: number = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private https: HttpsService,
    private constant: Constant,
    private commonService: CommonService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.flag = 0;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      if (this.sortedModules.length == 0) {
        this.https.httpGetWithHeader('profile').subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.profileData = res.data;
            this.checkDirection(this.profileData.lang ?? 'en');
            this.translate.setDefaultLang(this.profileData.lang ?? 'en');
            this.translate.use(this.profileData.lang ?? 'en');
            this.commonService.encryptLocalStorage(res.data, 'adminProfile');
            localStorage['appId'] = res.data.accessModuleId?.appId;
            this.sortedModules = this.constant.MODULES.basic;
            this.settings = this.commonService.decryptLocalStorage('settings');
            this.flag = 0;
            const promises = [];

            this.commonService.settingsData$
              .pipe(skip(1), delay(1000), takeUntil(this.destroy$))
              .subscribe((res: any) => {
                if (res !== undefined) {
                  this.settings = res;
                  if (this.settings && this.flag == 0) {
                    promises.push(this.sidebarChecks());
                    this.setLanguage(this.settings, this.profileData.lang);
                  }
                }
              });
            if (this.settings && this.flag == 0) {
              promises.push(this.sidebarChecks());
              this.setLanguage(this.settings, this.profileData.lang);
            }
            Promise.all(promises)
            .then(() => {
              this.defaultUrl = '/home';
              if (this.profileData?.step < 2) {
                this.defaultUrl = '/introduction';
              } else {
                this.defaultUrl = '/home';
              }
              resolve(true);
            })
            .catch((error) => {
              reject(error);
            });
          }
        });
      } else {
        this.defaultUrl = '/home';
        if (this.profileData?.step < 2) {
          this.defaultUrl = '/introduction';
        } else {
          this.defaultUrl = '/home';
        }
        resolve(true);
      }
    });
  }

  setLanguage(data, lang) {
    this.flag++;
    let langArray: any[] = [];
    this.languageList = [];
    if (data && data?.localizationFor == 1 && data?.allLanguange  && data?.allLanguange.length > 0) {
      data.allLanguange.forEach((item: any) => {
        let obj = {
          language: item.language,
          value: item.locale,
          isSelected: false,
        };
        this.languageList.push(obj);
      });
      this.languageList.forEach((val) => {
        langArray.push(val.value);
      });
      this.languageList.map((val) =>
        val.value == lang
          ? (val['isSelected'] = true)
          : (val['isSelected'] = false)
      );
      this.commonService.setLang(this.languageList);
      this.commonService.encryptLocalStorage(this.languageList, 'languages');
      this.translate.addLangs(langArray);
    } else {
      this.languageList.push({
        language: 'English',
        value: 'en',
        isSelected: true,
      });
      this.commonService.setLang(this.languageList);
      this.commonService.encryptLocalStorage(this.languageList, 'languages');
      this.commonService.setLang(this.languageList);
      this.translate.addLangs(['en']);
    }
  }

  sidebarChecks() {
    return new Promise((resolve, reject) => {
      if (
        this.settings &&
        (this.settings.isShuttle === undefined ||
          this.settings.isShuttle == false)
      ) {
        if (
          this.sortedModules.findIndex((item) => item.moduleCode === 15) !== -1
        ) {
          this.sortedModules.splice(
            this.sortedModules.findIndex((item) => item.moduleCode === 15),
            1
          );
        }
      }
      if (
        this.settings &&
        (this.settings?.isSos === false || this.settings?.isSos === undefined)
      ) {
        if (
          this.sortedModules.findIndex((item) => item.moduleCode === 13) !== -1
        ) {
          this.sortedModules.splice(
            this.sortedModules.findIndex((item) => item.moduleCode === 13),
            1
          );
        }
      }
      if (
        this.settings &&
        (this.settings?.customerMembershipPlan === false ||
          this.settings?.customerMembershipPlan === undefined) && (this.settings?.isWalletTopUp === false ||
            this.settings?.isWalletTopUp === undefined)
      ) {
        if (
          this.sortedModules.findIndex((item) => item.moduleCode === 14) !== -1
        ) {
          this.sortedModules.splice(
            this.sortedModules.findIndex((item) => item.moduleCode === 14),
            1
          );
        }
      }
      if (
        this.settings &&
        (this.settings?.isHiring === false ||
          this.settings?.isHiring === undefined)
          ) {
            if (
              this.sortedModules.findIndex((item) => item.moduleCode === 17) !== -1
              ) {
          this.sortedModules.splice(
            this.sortedModules.findIndex((item) => item.moduleCode === 17),
            1
          );
        }
      }
      let index = this.sortedModules.findIndex(
        (module) => module?.moduleCode == 10
      );
      if (index > -1) {
        let temp = this.sortedModules[index];
        this.sortedModules[index] =
          this.sortedModules[this.sortedModules.length - 1];
        this.sortedModules[this.sortedModules.length - 1] = temp;
      }
     
      resolve(true);
    })
  }

  public checkDirection(lang) {
    if (lang == 'en') {
      this.textDirection = 'ltr';
      this.document.body.classList.remove('direction');
    } else if (lang == 'ar') {
      this.textDirection = 'rtl';
      this.document.body.classList.add('direction');
    }
  }

  getObservableProfile() {
    this.https.httpGetWithHeader('profile').subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.profileData = res.data;
        if (this.profileData?.step < 2) {
          this.defaultUrl = '/introduction';
        } else {
          this.defaultUrl = this.sortedModules[0].route;
        }
      }
    });
  }
}
