<div class="wlk_wrp" mat-dialog-close>
  <figure><i class="fa-solid fa-xmark"></i></figure>
</div>

<div class="sucs_bkng_otr adtm_sc adcsmt_otr">
  <h3>{{type== 'edit' ? ('dispatcher_latest.Edit' | translate): ('dispatcher_latest.Add' | translate)}} CMS</h3>
  <div class="ad_sm">
    <form action="" [formGroup]="cmsForm">
      <div class="cms_ad">
        <div>
          <label>{{'dispatcher_latest.Cms_title'|translate}}</label>
          <mat-form-field class="example-full-width" floatLabel="never">
            <input matInput placeholder="{{'dispatcher_latest.Cms_title'|translate}}" type="text" class="form_control" formControlName="cmsTitle" />
          </mat-form-field>
          <mat-error *ngIf="
                      submitted && cmsForm.controls['cmsTitle'].hasError('required')
                    ">{{'dispatcher_latest.cms_req_msg'|translate}}</mat-error>
        </div>

        <div>
          <label>{{'dispatcher_latest.Show_Type'|translate}}</label>
          <mat-form-field class="example-full-width" floatLabel="never">
            <mat-select class="form_control" placeholder="{{'dispatcher_latest.Show_Type'|translate}}" formControlName="showType" multiple
              (selectionChange)="btn_change($event.value)">
              <mat-option value="FRONT" *ngFor="let data of cmstype" [value]="data?.value">{{data?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="
                      submitted && cmsForm.controls['showType'].hasError('required')
                    ">{{'dispatcher_latest.Show_req_msg'|translate}}</mat-error>
        </div>

        <div *ngIf="required">
          <label>{{'dispatcher_latest.chck_req_lbl'|translate}}</label>
          <mat-form-field class="example-full-width" floatLabel="never">
            <mat-select class="form_control" placeholder="{{'dispatcher_latest.Required'|translate}}" formControlName="isCheckBox">
              <mat-option *ngFor="let data of cmsRequired" [value]="data?.value">{{data?.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div >
        <label>{{'dispatcher_latest.CMS_Content'|translate}}</label>
        <ngx-editor-menu [editor]="editors.editor"> </ngx-editor-menu>
        <ngx-editor [editor]="editors.editor" formControlName="cmsContent"></ngx-editor>
        <mat-error *ngIf="
                    submitted && cmsForm.controls['cmsContent'].hasError('required')
                  ">{{'dispatcher_latest.CMS_Content_req'|translate}}</mat-error>
      </div>
      <div class="dfdfffd">
        <button class="btn btn-primary" (click)="btn_add()">{{'requiredDocuments.Add'|translate}}</button>
      </div>
    </form>
  </div>

