<share-button [theme]="'material-dark'" [button]="'facebook'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'email'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'twitter'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'linkedin'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'reddit'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'whatsapp'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>
<share-button [theme]="'material-dark'" [button]="'copy'" [showText]="true" [url]="url"
    [autoSetMeta]="false"></share-button>