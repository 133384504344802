import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InitalserviceService } from 'src/app/services/initalservice.service';

@Component({
  selector: 'app-success-booking',
  templateUrl: './success-booking.component.html',
  styleUrls: ['./success-booking.component.scss']
})
export class SuccessBookingComponent implements OnInit {
  modules: any;

  constructor(private router: Router,public dialogRef: MatDialogRef<SuccessBookingComponent>, 
    public dialog: MatDialog,private initalservice:InitalserviceService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.modules = this.initalservice.modules();
  }

  routerLink(){
    this.dialog.closeAll();
    this.router.navigateByUrl('/bookings/bookingDetails/'+this.data._id)
  }


}
