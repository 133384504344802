import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddCmsComponent } from './add-cms/add-cms.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { AddTeamComponent } from './add-team/add-team.component';
import { ChoosePlansComponent } from './choose-plans/choose-plans.component';
import { DummyBookingsComponent } from './dummy-bookings/dummy-bookings.component';
import { FullPayComponent } from './full-pay/full-pay.component';
import { ImageComponent } from './image/image.component';
import { PermissionComponent } from './permission/permission.component';
import { SuccessBookingComponent } from './success-booking/success-booking.component';
import {ShareBookingsComponent} from './share-bookings/share-bookings.component';
import { DriverListComponent } from './driver-list/driver-list.component';
import { BookingListingsComponent } from './booking-listings/booking-listings.component';
import { ShowDeliveyProofComponent } from './show-delivey-proof/show-delivey-proof.component';
@Injectable({
  providedIn: 'root',
})
export class DailogServiceService {
  token: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  openSuccess(data) {
    const dialogRef = this.dialog.open(SuccessBookingComponent, {
      panelClass: 'class',
      data: data,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  addTeam() {
    const dialogRef = this.dialog.open(AddTeamComponent, {
      panelClass: 'class',
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  plans(data) {
    let disable: boolean = false;
    if (data == 'off') {
      disable = true;
    }

    const dialogRef = this.dialog.open(ChoosePlansComponent, {
      panelClass: 'class',
      disableClose: disable,
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  dummyBooking() {
    const dialogRef = this.dialog.open(DummyBookingsComponent, {
      panelClass: 'class',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'yes') {
        let url: string = '';
        url = this.router.url;
        this.router
          .navigateByUrl('/refresh', { skipLocationChange: true })
          .then(() => {
            this.router.navigateByUrl(url);
          });
      }
    });
  }

  showDeliveryProof(data:any){
    const dialogRef = this.dialog.open(ShowDeliveyProofComponent, {
      panelClass: 'prdf',
      data: data,
    });
  }

  image(data) {
    const dialogRef = this.dialog.open(ImageComponent, {
      panelClass: 'image_class',
      data: data,
    });
  }

  permission(data) {
    const dialogRef = this.dialog.open(PermissionComponent, {
      panelClass: 'image_class',
      data: data,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  doc() {
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      panelClass: 'doc_class',
    });
  }

  cms() {
    const dialogRef = this.dialog.open(AddCmsComponent, {
      panelClass: 'cms_class',
    });
  }

  listing(id) {
    const dialogRef = this.dialog.open(DriverListComponent, {
      panelClass: 'drv_lst',
      data:id
    });
  }
  
  bookinglisting(id) {
    const dialogRef = this.dialog.open(BookingListingsComponent, {
      panelClass: 'drv_lst',
      data:id
    });
  }

  FullPay(data) {
    const dialogRef = this.dialog.open(FullPayComponent, {
      panelClass: 'fullPay_class',
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'yes') {
        let url: string = '';
        url = this.router.url;
        this.router
          .navigateByUrl('/refresh', { skipLocationChange: true })
          .then(() => {
            this.router.navigateByUrl(url);
          });
      }
    });
  }

  shareButtons(data){
    const dialogRef = this.dialog.open(ShareBookingsComponent, {
      panelClass: 'fullPay_class',
      data:data
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }


}
