import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { RoleService } from 'src/app/services/role/role.service';
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {

  constructor(private https:HttpsService,public dialogRef: MatDialogRef<PermissionComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,private commonService: CommonService,private router: Router,private route: ActivatedRoute,
    private roleService: RoleService) { }

  ngOnInit(): void {
  }

  granted(){
    const body={
      "code":this.data
    }
    this.https.httpPostWithHeader('verifyConnection',body,false).subscribe((res:any)=>{
      if(res['statusCode'] == 200){
        this.commonService.snackbarSuccess(res.message, '', 2000);
        this.dialogRef.close();
      }
    })
  }

  close(){

    const body={
      code:this.data,
      deny:true
    }
    this.https.httpPostWithHeader('verifyConnection',body,false).subscribe((res:any)=>{
      if(res['statusCode'] == 200){
        this.commonService.snackbarSuccess(res.message, '', 2000);
      }
        if (this.data) {
          this.router.navigateByUrl('/auth/login?code='+ this.data);
          localStorage.clear();
          this.dialogRef.close()
        }
    })

   
  }

}
