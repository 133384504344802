<div class="sucs_bkng_otr adtm_sc adtrm_otr">
    <h3>{{'dispatcher_latest.PaymentDetails'|translate}}</h3>
    <div class="df">
        <label>{{'dispatcher_latest.Amount'|translate}}</label>
        <mat-form-field class="example-full-width" floatLabel="never">
            <input matInput placeholder="{{'dispatcher_latest.Amount'|translate}}" type="number" class="form_control" [(ngModel)]="amount"
                appNumberDotOnly />
        </mat-form-field>
    </div>
    <div class="dff pt-3">
        <button class="btn btn-primary" (click)="pay()">{{'dispatcher_latest.Pay'|translate}}</button>
        <button class="btn btn-primary" mat-dialog-close>{{'dispatcher_latest.Close'|translate}}</button>
    </div>
</div>