<div class="cntnt_hd">
  <h3>Drivers</h3>
</div>
<div class="bkng_lstb_dtl tm_record mt-3 txt_fdx">
  <div class="ld_chkbx">
    <mat-radio-group class="radio" [(ngModel)]="selected">
      <mat-radio-button class="radio-custom" *ngFor="let data of driverData" [value]="data?._id._id">
        <div class="dr_pop">
          <h2>Driver No {{data?._id?.driverNo}}</h2>
          <p>{{data?._id?.firstName+ " " + data?._id?.lastName }}</p>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <h6 class="data-not-fnd" *ngIf="driverData && driverData?.length === 0">
    <figure style="text-align: center">
      <img src="assets/images/nodata.png" alt="" />
    </figure>
  </h6>
  <div class="pt-3 btn_asng" *ngIf="driverData && driverData?.length > 0">
    <button class="btn btn-primary" (click)="btn_add()">Assign</button>
    <button class="btn btn-primary scd_bt" mat-dialog-close="">Close</button>
  </div>
</div>