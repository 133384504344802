import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-full-pay',
  templateUrl: './full-pay.component.html',
  styleUrls: ['./full-pay.component.scss']
})
export class FullPayComponent implements OnInit {
  driverId: any;
  amount:number=0;
  constructor(private translate:TranslateService,private https:HttpsService,private commonService: CommonService,public dialogRef: MatDialogRef<FullPayComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.driverId=this.data
  }

  pay(){
    if(this.amount!=0){
      this.https
        .httpPostWithHeader('manualPayoutDriver', { driverId: this.driverId,amount:this.amount}, false)
        .subscribe((response: any) => {
          if(response.statusCode == 200){
            this.commonService.snackbarSuccess(response.message, '', 2000)
           this.dialogRef.close('yes');
          }
      
        });
    }else{
      this.commonService.snackbarError(this.translate.instant('dispatcher_latest.pay_req_msg'),'',2000);
    }
  }

}
