export class apiList {
  login: string = '/admin/login';
  signup: string = '/admin/signup';
  logout: string = '/admin/logout';
  changePassword: string = '/admin/changePassword';
  verifyOtp: string = '/admin/verifyOtp';
  forgotPassword: string = '/admin/forgotPassword';
  getAllcustomer: string = '/admin/customer';
  customerStatus: string = '/admin/customer/status';
  driverStatus: string = '/admin/driver/status';
  driver: string = '/admin/driver';
  getDriver: string = '/admin/getDriverList';
  profile: string = '/admin/getProfile';
  service: string = '/admin/service';
  discountStatus: string = '/admin/discount/status';
  customerBookingHistory: string = '/admin/customerBookingHistory';
  driverBookingHistory: string = '/admin/driverBookingHistory';
  dashboard: string = '/admin/dashboard';
  jobs: string = '/admin/jobs';
  job: string = '/admin/job';
  driverRequest: string = '/admin/driverRequest';
  changeSerials: string = '/admin/changeSerials';
  driverTip: string = '/admin/driverTip';
  documentUpload: string = '/admin/documentUpload';
  upload: string = '/admin/upload';
  zone: string = '/admin/zone';
  cancelJob: string = '/admin/cancelJob';
  cms: string = '/admin/cms';
  addFaq: string = `/admin/addFaq`;
  editFaq: string = `/admin/editFaq`;
  rearrangingFaq: string = `/admin/changeArrangingOrderFaq`;
  getFaq: string = `/admin/getAllFaq`;
  assignJob: string = '/admin/assignJob';
  query: string = '/admin/query';
  adminNotes: string = '/admin/adminNotes';
  completeQuery: string = '/admin/completeQuery';
  serviceStatus: string = '/admin/service/status';
  uncompleteJob: string = '/admin/uncompleteJob';
  driverQuestionAnswer: string = '/admin/driverQuestionAnswer';
  completeJob: string = '/admin/completeJob';
  getAllcustomerExport: string = '/admin/getAllcustomerExport';
  driverExport: string = '/admin/driverExport';
  driverVehicleDocuments:string = '/admin/driverVehicleDocuments';
  approvedocument:string = '/admin/approveDocument';
  driverRatingReview: string = '/admin/getReviewsById';
  zoneExport: string = '/admin/zoneExport';
  customerBookingHistoryExport: string = '/admin/customerBookingHistoryExport';
  vehicleCategory: string = '/admin/vehicleCategory';
  area: string = '/admin/area';
  driverBookingHistoryExport: string = '/admin/driverBookingHistoryExport';
  vehicle: string = '/admin/vehicle';
  walkthrough: string = '/admin/walkthrough';
  revenueEarning: string = '/admin/getRevenueEarning';
  revenuePaymentsExport: string = '/admin/getRevenuePaymentExport';
  revenueEarningExport: string = '/admin/getRevenueEarningExport';
  vUpload: string = '/upload/upload';
  settings: string = '/admin/settings';
  driverVehicleById: string = '/admin/driverVehicleById';
  getAccessModules: string = '/admin/getAccessModules';
  accessModules: string = '/admin/accessModules';
  signupSubAdmin: string = '/admin/signupSubAdmin';
  getSubAdmin: string = '/admin/getSubAdmin';
  updateSubAdmin: string = '/admin/updateSubAdmin';
  deleteSubAdmin: string = '/admin/deleteSubAdmin';
  updateAccessModules: string = '/admin/updateAccessModules';
  leaderboard: string = '/admin/leaderboard';
  getAllNotification: string = '/admin/getAllNotification';
  sendBulkNotification: string = '/admin/sendBulkNotification';
  driverVehicle: string = '/admin/driverVehicle'
  banner: string = '/admin/banner';
  manageAnalytics: string = '/admin/manageAnalytics';
  getFleetDashboardList: string = '/admin/getFleetDashboardList';
  jobsExport: string = '/admin/jobsExport';
  recievableExport: string = '/admin/recievableExport';
  orderVehicleCategory: string = '/admin/orderVehicleCategory';
  chargeExtraAmount: string = '/chargeExtraAmount';
  orderDiscount: string = '/admin/orderDiscount';
  supportHelp: string = '/user/supportHelp';
  customer: string = '/admin/customer';
  bookingTrack: string = '/bookingTrack';
  wallet:string='/admin/wallet'
  logOut: string = '/admin/logout';
  bookingSuggested: string = '/admin/bookingSuggested';
  vat: string = '/admin/vat';
  assignSuggestedBooking: string = '/admin/assignSuggestedBooking';
  driverList: string = '/admin/driverList';
  customerList: string = '/admin/customerList';
  pricingVehicleCategory: string = '/admin/addPricingVehicleCategory';
  updateAddressServices: string = '/admin/updateAddressServices';
  addPricingZone: string = '/admin/addPricingZone';
  creditHistoryByCustomer: String = '/admin/creditHistoryByCustomer';
  approveB2BCustomer: String = '/admin/approveB2BCustomer';
  userTransaction: string = '/admin/userTransaction'
  userTransactionExport: string = '/admin/userTransactionExport'
  team: string = '/admin/team';
  // driverMulti: string = '/admin/driverMulti';
  driverMulti: string = '/admin/driver';


  //settings
  globalSettings: string = '/admin/globalSettings';
  updateGlobalSettings: string = '/admin/updateGlobalSettings';
  webhooks: string = '/webhook';
  apiCredentials: string = '/apiCredentials'
  events: string = '/events'
  booking: string = '/admin/booking';
  getAllPlans: string = '/admin/getAllPlans';
  addCard: string = '/admin/addCard'
  subscribe: string = '/admin/subscribe';
  upcomingInvoices: string = '/admin/upcomingInvoices';
  getAllInvoices: string = '/admin/getAllInvoices';
  createMultipeBooking: string = '/admin/createMultipeBooking';
  verifyConnection: string = '/admin/verifyConnection';
  vehiclesTypes: string = '/admin/vehiclesTypes'
  businessTypes: string = '/admin/businessTypes';
  accountSetup: string = '/admin/accountSetup';
  sendOtp: string = '/admin/sendOtp';
  bulkBooking: string = '/admin/bulkBooking';
  pricing: string = '/admin/pricing';
  tag: string = '/admin/tag';
  plugin: string = '/admin/plugin';
  getAllPlugins: string = `/admin/plugins`;
  managePaymentMethods: string = `/admin/managePaymentMethods`;
  manageSmsMethods: string = `/admin/manageSmsMethods`;
  managefeatureoff: string = `/admin/manageFeatureOff`;
  manageEmailMethods: string = `/admin/manageEmailMethods`;
  manageCallMaskingMethods: string = `/admin/manageCallMaskingMethods`;
  requiredPlugins: string = '/admin/requiredPlugins';
  requiredDocument:string='/admin/requiredDocument';
  multipleRequiredDocument: string = '/admin/multipleRequiredDocument';
  getRequiredDocument: string = '/admin/getRequiredDocument';
  getRequiredDocumentByDriver: string = '/admin/getRequiredDocumentByDriverNew';
  approveMultipleDocuments: string = '/admin/approveMultipleDocuments';
  getRevenueEarningExport: string = '/admin/getRevenueEarningExport';
  zoneTagTeam: string = '/admin/zoneTagTeam';
  subscription: string = '/admin/subscription';
  driverByTeam: string = '/admin/driverByTeam';
  getDriverBooking: string = '/admin/getDriverBooking';
  getAllTeamData: string = '/admin/getAllTeamData';
  assignMultipleJob: string = '/admin/assignMultipleJob';
  surcharge: string = '/admin/surcharge';
  payoutRequests: string = '/admin/payoutRequests';
  driverEarningHistory: string = '/admin/driverEarningHistory'
  updatePayoutRequest: string = '/admin/updatePayoutRequest';
  payoutRequestsExport: string = '/admin/payoutRequestsExport';
  bankdetail: string = '/admin/bankdetail';
  bankdetailMultiple: string = '/admin/bankdetailMultiple';
  vehicleApproved: string = '/admin/vehicleApproved';
  manualPayoutDriver: string = '/admin/manualPayoutDriver';
  status: string = '/admin/status';
  getStatus: string = '/admin/getStatus';
  driverSlotbyId: string = '/admin/driverSlotbyId';
  driverSlot: string = '/admin/driverSlot';
  reviewByBooking: string = '/admin/reviewByBooking';
  template: string = '/admin/template';
  vehicleCompany = '/admin/vehicleCompany'
  // airport charge information
  surgeZone: string = '/admin/surgeZone';
  // Question Asnwer
  question: string = '/admin/question';
  // dynamic status
  constant: string = `/admin/constant`;
  // stripe Payout
  getAllPayout: string = '/admin/getAllPayout';
  makeDriverPay: string = '/admin/makeDriverPay';
  //Mmbership
  getAddPutMembership: string = `/admin/subscription`;
  //SOS
  SOS: string = `/admin/sosRequest`;

  // Shuttle
  location:string=`/admin/location`;
  routes:string=`/admin/routes`
  updateProfile:string=`/admin/updateProfile`;
  seat:string=`/admin/seat`
  shuttleBooking:string=`/admin/shuttleBooking`;
  student:string=`/admin/student`

  //NotificationModule
  broadCast:string = `/admin/broadCast`;


  //surgePricing
  surgePricing:string=`/admin/surgePricing`;
  weatherCode:string=`/admin/weatherCode`;

  //Hiring Package
  hiringPackage:string=`/admin/hiringPackage`

  //Tax
  tax:string=`/admin/tax`
  

}

