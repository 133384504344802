import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
import { InitalserviceService } from 'src/app/services/initalservice.service';

@Component({
  selector: 'app-dummy-bookings',
  templateUrl: './dummy-bookings.component.html',
  styleUrls: ['./dummy-bookings.component.scss']
})
export class DummyBookingsComponent implements OnInit {


  counts: any
  lat: any;
  lng: any;
  range: any
  dummyForm:FormGroup;
  modules: any;
  address: any;
  constructor(private https: HttpsService, private commonService: CommonService,private translate:TranslateService,
    private fb: FormBuilder,public dialog: MatDialog,public dialogRef: MatDialogRef<DummyBookingsComponent>,private initalservice:InitalserviceService){ }

  ngOnInit(): void {
    this.modules= this.initalservice.modules();
  }

  creatForm(){
  }

  handlePickUpChange(address) {
    if (address.formatted_address) {
      // this.address=address.formatted_address
      this.lat = (address.geometry.location.lat())
      this.lng = (address.geometry.location.lng())

    }
  }

  btn_add() {
    if(this.range > 5){
      const body = {
        "latitude": this.lat,
        "longitude": this.lng,
        "count":this.counts,
        "radius":this.range,
        // "address": this.address,
       "pickup":{
       "latitude": this.lat,
        "longitude":  this.lng
       },
        "delivery":{
       "latitude": this.lat,
        "longitude":  this.lng
       }
      }
      this.https.httpPostWithHeader('createMultipeBooking', body, true).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.snackbarSuccess(res.message,'',2000);
          this.dialogRef.close('yes')
        }
      })
    }else{
      this.commonService.snackbarError(this.translate.instant('dispatcher_latest.range_greater_tha'),'',2000);
    }
  }

}
