import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BookingCommonService } from 'src/app/pages/bookings/booking-common.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Constant } from 'src/app/services/common/constant';
import { HttpsService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-driver-list',
  templateUrl: './driver-list.component.html',
  styleUrls: ['./driver-list.component.scss']
})
export class DriverListComponent implements OnInit {
  jobsData: any;
  jobIds: any=[];

  constructor(private https:HttpsService,private common:CommonService,private translate:TranslateService,
    private bookingService:BookingCommonService,public constant: Constant, public dialogRef: MatDialogRef<DriverListComponent>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.jobs();
  }

  jobs() {
    var param ='jobs=0';
    this.https.httpGetWithHeaderParam('jobs', param).subscribe(
      (res: any) => {
        this.jobsData = res.data;
        this.bookingService
          .getNewFormatOfBooking(res.data)
          .then((result: any) => {
            this.jobsData = result;
          });
      },
      (error) => console.log(error)
    );
  }

  btn_selection(value,data){
    if(value == true){
      this.jobIds.push(data?._id)
    }else if(value == false){
      let index= this.jobIds.findIndex(c=> c==data._id);
      this.jobIds.splice(index,1);
    }
  }

  btn_add(){
    if(this.jobIds.length > 0){
      let body={
        driverId:this.data,
        ids:this.jobIds
      };
      this.https.httpPostWithHeader('assignMultipleJob',body,true).subscribe((res:any)=>{
        if(res.statusCode == 200){
          this.dialogRef.close();
          this.common.snackbarSuccess(res['message'], '', 2000)
        }
      })
    }else{
      this.common.snackbarError(this.translate.instant('dispatcher_latest.sel_book_conti'),'',2000);
    }
  }

}
