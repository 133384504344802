import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from '../role/role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private roleService: RoleService) {



  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (next.routeConfig.path === 'web') {
      return true;
    } else if (next.routeConfig.path === 'auth') {
      let token = next.queryParams['token']
      let accessToken = localStorage.getItem('accessToken');
      if (token && accessToken) {
        localStorage.clear();
        return true
      }
      if (localStorage['accessToken'] !== undefined) {
        this.roleService.getProfile().then((profile) => {
          if (profile) {
            this.router.navigateByUrl(this.roleService.defaultUrl);
          }
        });
        return false;
      } else {
        return true;
      }
    } else {
      if (localStorage['accessToken'] == undefined) {
        localStorage.clear();
        this.router.navigateByUrl('/auth/login');
        return false
      } else {
        return true
      }
    }
  }
}
