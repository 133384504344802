export const environment = {
  production: true,
  baseUrl: 'https://stagingapi.zogcitaxi.com/dispatcher/api/v1',
  imageUrl: 'https://stagingapi.zogcitaxi.com/dispatcher',
  socketUrl: 'https://stagingdispatcherapi.zogcitaxi.com/',
  webhookUrl:'https://stagingapi.zogcitaxi.com/dispatcher/api/v1/rest',
  stripeRedirectionUrl:'https://stagingapi.zogcitaxi.com/dispatcher/api/v1/admin/settings',
  bulkUrl:'https://stagingapi.zogcitaxi.com/dispatcher/template/bulkBookings.xlsx',
  trackUrl:'https://stagingsdispatcher.zogcitaxi.com/web/track/',
  cmsUrl: 'https://stagingsdispatcher.zogcitaxi.com/web/cms/',
  faqUrl:'https://stagingsdispatcher.zogcitaxi.com/web/faq',
  iv:'D904363DB8DACEB8',
  key:'MKOIJNQASDFVRGHNBHUCFTXDRESZWA'


};

