<div class="sucs_bkng_otr adtm_sc adcsmt_otr">
    <h3>Add Dummy {{modules?.booking}}</h3>
    <form action="">
        <div class="ad_sm">
            <div>
                <mat-form-field class="example-full-width" floatLabel="never">
                    <input matInput class="form_control" placeholder="Address" name="PickupLocation" ngx-google-places-autocomplete
                        #search (onAddressChange)="handlePickUpChange($event)">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width" floatLabel="never">
                    <input matInput placeholder="No of {{modules?.booking}}" min="0" type="number" class="form_control" [(ngModel)]="counts"
                    [ngModelOptions]="{standalone: true}" />
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="example-full-width" floatLabel="never">
                    <input matInput placeholder="Range(in km)"  type="number" class="form_control" [(ngModel)]="range"
                    [ngModelOptions]="{standalone: true}"/>
                </mat-form-field>
            </div>
            <div class="dfdfffd ">
                <button class="btn btn-primary" (click)="btn_add()">Add</button>
                <button class="btn btn-primary" mat-dialog-close>Cancel</button>
            </div>
        </div>
    </form>
</div>