import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  signUpData = new BehaviorSubject<any>('');
  resetData = new BehaviorSubject<any>('');
  isLoggedOut = new Subject();
  secretKey: any = 'apptunix';
  profileData$ = new Subject<string>();
  settingsData$ = new BehaviorSubject<any>(undefined);
  langData$ = new BehaviorSubject<any>(undefined);
  userData = new BehaviorSubject<any>('');
  selector = new BehaviorSubject<any>('');
  modulesData = new BehaviorSubject<any>('');
  driverFIlterData$ = new Subject<string>();
  settings: any;
  title: any;
  key = 'MKOIJNQASDFVRGHNBHUCFTXDRESZWA' //hashing the key using SHA256
  iv = CryptoJS.enc.Base64.parse(environment.iv); //giving empty initialization vector
  version = new BehaviorSubject<any>('');
  sharedData: any;
  constructor(private _snackBar: MatSnackBar, private router: Router,private translate:TranslateService) {}

  setLang(value) {
    this.langData$.next(value);
  }

  getLang(): Observable<boolean> {
    return this.langData$.asObservable();
  }


  setSettings(value) {
    this.settingsData$.next(value);
  }

  getSettings(): Observable<boolean> {
    return this.settingsData$.asObservable();
  }

  setVersion(value) {
    this.version.next(value);
  }

  getVersion(): Observable<boolean> {
    return this.version.asObservable();
  }

  encryptLocalStorage(data, key) {
    let encyrpted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.secretKey
    ).toString();
    localStorage.setItem(key, encyrpted);
  }

  decryptLocalStorage(key) {
  
    if(key){
      let data = localStorage.getItem(key);
      if(data){
        let bytes = CryptoJS.AES.decrypt(data, this.secretKey);
        let recieved = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(recieved);
      }
  
    }
 
  }
  decryptData(encrypted) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  setModules(value) {
    this.modulesData.next(value);
  }

  getModules(): Observable<boolean> {
    return this.modulesData.asObservable();
  }
  setSharedData(data: any) {
    this.sharedData = data;
  }
  
  getSharedData(): any {
    return this.sharedData;
  }

  setUserInfo(value) {
    this.userData.next(value);
  }

  getUserInfo(): Observable<boolean> {
    return this.userData.asObservable();
  }

  //selector methods
  setSelectorInfo(value) {
    this.selector.next(value);
  }

  getSelectorInfo(): Observable<boolean> {
    return this.selector.asObservable();
  }

  getCurrency() {
    let currency = this.decryptLocalStorage('settings');
    if (currency) {
      return currency?.currency;
    }
  }

  getSignUpData(): Observable<boolean> {
    return this.signUpData.asObservable();
  }

  setSignUpData(value) {
    this.signUpData.next(value);
  }

  setResetData(value) {
    this.resetData.next(value);
  }

  snackbarSuccess(content, action, duration) {
    let sb = this._snackBar.open(content, action, {
      duration: duration,
    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
  }

  snackbarError(content, action, duration) {
    let sb = this._snackBar.open(content, action, {
      duration: duration,
      panelClass: ['custom-style'],
    });
    sb.onAction().subscribe(() => {
      sb.dismiss();
    });
  }

  snackbarBooking(content, action, duration) {
    let sb = this._snackBar.open(content, action, {
      duration: duration,
      panelClass: ['custom-styles'],
    });
    sb.onAction().subscribe(() => {
      this.router.navigateByUrl('/bookings');
    });
  }

  public isLoggedIn() {
    return localStorage['accessToken'] ? true : false;
  }
  setTitleByModuleKey(item) {  
  if(item.team.includes('foodDeliveryApp')){
    this.title = "Resturants";  
    this.title = this.translate.instant('dispatcher_latest.Resturants')
  }else if(item.team.includes('groceryApp')){
    // this.title = "Store"; 
    this.title = this.translate.instant('dispatcher_latest.Store')

  }else if(item.team.includes('pharmacy')){
    // this.title = "Pharmacy" ;
    this.title = this.translate.instant('dispatcher_latest.Pharmacy')

  }
  else if(item.team.includes('advanceecommerce')){
    // this.title = "Store " ;
    this.title = this.translate.instant('dispatcher_latest.Store')

  }
  else if(item.team.includes('laundry')){
    // this.title = "Laundry" ;
    this.title = this.translate.instant('Resturants.laundry')

  }
   return this.title;
  }

  confirmToast(type?) {
    return Swal.fire({
      title: this.translate.instant('dispatcher_latest.Areyousure'),
      text: !type? this.translate.instant('dispatcher_latest.Youwonbeabletorevertthis'):this.translate.instant('dispatcher_latest.logout_msg'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      cancelButtonText:this.translate.instant('dispatcher_latest.Cancel'),
      confirmButtonText: !type? this.translate.instant('dispatcher_latest.YesDeleteIt'):this.translate.instant('dispatcher_latest.Yes'),
    }).then();
  }
  logoutConfirmToast() {
    return Swal.fire({
      title: this.translate.instant('dispatcher_latest.logout_msg'),
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      cancelButtonText:this.translate.instant('dispatcher_latest.Cancel'),
      confirmButtonText: this.translate.instant('dispatcher_latest.Yes'),
    }).then();
  }
  getMonthAccrNum(month){
    let name;
    switch(month){
      case 1:{
        name='January';
        break;
      }
      case 2:{
        name='February';
        break;
      }
      case 3:{
        name='March';
        break;
      }
      case 4:{
        name='April';
        break;
      }
      case 5:{
        name='May';
        break;
      }   case 6:{
        name='June';
        break;
      }   case 7:{
        name='July';
        break;
      }   case 8:{
        name='August';
        break;
      }   case 9:{
        name='September';
        break;
      }   case 10:{
        name='October';
        break;
      }   case 10:{
        name='November';
        break;
      }   case 12:{
        name='December';
        break;
      }
    }
    return name;
  }
  changeTimeInpToMin(startTime) {
    startTime =
      startTime !== ""
        ? Number(startTime.split(":")[0]) * 60 + Number(startTime.split(":")[1])
        : 0;
    return startTime;
  }

  convertMinToStr(startTime) {
    if (startTime > 0) {
      let newStartTime;
      newStartTime = String(Math.floor(startTime / 60)) + ":";
      if (startTime - Number(Math.floor(startTime / 60)) * 60 < 10) {
        startTime =
          newStartTime +  
          `0${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      } else {
        startTime =
          newStartTime +
          `${startTime - Number(Math.floor(startTime / 60)) * 60}`;
      }

    } else {
      if (startTime === 0) {
        startTime = "00:00";
      }

    }
    return startTime
  }
  
  deleteEmptyKeys(value) {
    for (const key in value) {
      if (
        value[key] === "" ||
        value[key] === null ||
        value[key] === undefined
      ) {
        delete value[key];
      }
    }
    return value;
  }

}

