import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CommonService } from './common/common.service';
import { HttpsService } from './http/http.service';
import { ScriptLoadService } from './maps.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from './role/role.service';

@Injectable({
  providedIn: 'root',
})
export class InitalserviceService {
  data: any;
  settings: any;
  languageList: Array<any> = [];
  languageSelected: any;
  textDirection: string;
  profile: any;

  constructor(
    private https: HttpsService,
    private scriptLoadService: ScriptLoadService,
    @Inject(DOCUMENT) private document: Document,
    private commonService: CommonService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private title: Title
  ) {
    this.load();
  }

  async load() {
    let token = localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : null;
      let appId = localStorage.getItem('appId')
      if (token || appId) {
        await this.getProjectInfo();
      }  
  }

  async getProjectInfo() {
    try {
      let resp: any = await this.https
        .httpGetWithHeaderInital('globalSettings')
        .subscribe((res: any) => {
          if (res['statusCode'] == 200) {
            this.data = res['data'];
            this.commonService.encryptLocalStorage(this.data, 'settings');
            this.commonService.setSettings(this.data);            
            if (res.data.dispatcherApiPrefix) {
              this.commonService.setVersion(res.data.dispatcherApiPrefix);
              localStorage.setItem('version', res.data.dispatcherApiPrefix);
            }
            if (this.data) {
              this.modules();
            }
            this._document
              .getElementById('appFavicon')
              .setAttribute('href', this.data.favIcon);
            this.title.setTitle(this.data['companyName']);
            this.themeHandle(this.data);
            this.scriptLoadService
              .loadScript(this.data.googleApiKeyPanel)
              .then(() => {});
          } else {
            console.log(`Failure From Admin - ${res['message']}`);
          }
        });
    } catch (err) {
      console.log(`Failure From Great Admin - ${err}`);
    }
  }

  themeHandle(data) {
    document.documentElement.style.setProperty(
      '--primaryColor',
      data['primaryColor']
    );
    document.documentElement.style.setProperty(
      '--secondaryColor',
      data['secondaryColor']
    );
    document.documentElement.style.setProperty(
      '--textColor',
      data['textColor']
    );
  }

  modules() {
    if (this.data?.modules) {
      this.settings = this.data?.modules;
      this.commonService.setModules(this.settings);
      return this.settings;
    }
  }
}
