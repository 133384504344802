import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import {MaterialModule} from './material/material/material.module';
import { SidebarComponent } from './sidebar/sidebar.component'
import { SafeHtmlPipePipe } from '../core/safe-html-pipe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ArabicNumberPipePipe } from '../core/arabicNumber/arabic-number-pipe.pipe';
import { FormsModule } from '@angular/forms';

import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent,SafeHtmlPipePipe, SelectCheckAllComponent,ArabicNumberPipePipe],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    FormsModule
  ],
  exports: [HeaderComponent,FooterComponent,MaterialModule,SidebarComponent,SafeHtmlPipePipe,ArabicNumberPipePipe,SelectCheckAllComponent,MatSelectModule,MatCheckboxModule]
})
export class SharedModule { }
