<div class="wlk_wrp" mat-dialog-close>
  <figure><i class="fa-solid fa-xmark"></i></figure>
</div>

<div class="modal-body">
  <div class="trial_modal">
    <figure>
      <img src="assets/images/plan.jpg" alt="" />
    </figure>
  </div>
  <div class="trial_content">
    <h2>
      {{'dispatcher_latest.Choosethesuitableplanforyou'|translate}}
      <!-- <span>Please pick a plan</span> -->
    </h2>

    <p>
      {{'dispatcher_latest.biilingPara'|translate}}
      
    </p>
  </div>
</div>

<div class="mrktplce" *ngIf="plans && plans.length > 0">
  <div class="modal-body">
    <div>
      <i class="logout-icon" *ngIf="!adminProfile?.isOnTrial" style="cursor: pointer;" (click)="btn_logOut()">
        <img src="assets/images/logout.jpg" alt="" />
      </i>
      <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfigType1">
        <ng-container ngxSlickItem *ngFor="let slide of plans" class="slide">
          <div class="main-container">
            <div class="plan-card">
              <div class="section pricing-sections col-12">
                <p class="heading text-center new-text-ui" style="font-size: 29px !important">
                  Marketplace
                </p>
                <div class="outerDiv">
                  <div class="col-12">
                    <h1 class="price discounted_price" style="direction: ltr">
                      <span class="heading"> $ </span>
                      {{ slide?.price }}
                      <sub class="billed-anually"> /{{ slide?.recurringType }}</sub>
                    </h1>
                    <p class="billed-anually" style="direction: ltr">
                      (Billed Annually)
                    </p>

                    <ul class="pln_dtls">
                      <li>
                        <div class="plan-description">
                          <figure>
                            <img src="assets/images/checkmark.webp" alt="" />
                          </figure>
                          <span> Branded Web platform for customers </span>
                        </div>
                      </li>
                      <li>
                        <div class="plan-description">
                          <figure>
                            <img src="assets/images/checkmark.webp" alt="" />
                          </figure>
                          <span> Branded Web platform for customers </span>
                        </div>
                      </li>
                      <li>
                        <div class="plan-description">
                          <figure>
                            <img src="assets/images/checkmark.webp" alt="" />
                          </figure>
                          <span> Branded Web platform for customers </span>
                        </div>
                      </li>
                      <li>
                        <div class="plan-description">
                          <figure>
                            <img src="assets/images/checkmark.webp" alt="" />
                          </figure>
                          <span> Branded Web platform for customers </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <button class="btn btn-primary btn-style mt-3" [disabled]="slide?.isPurchased"
                    (click)="purchasePlan(slide?.stripePriceId, slide)">
                    Choose Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ngx-slick-carousel>
    </div>
  </div>
</div>