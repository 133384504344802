import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { GetInterceptorService } from './services/interceptor/get/get-interceptor.service';
import { SetInterceptorService } from './services/interceptor/set/set-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { apiList } from './services/http/api-list';
import { MaterialModule } from './shared/material/material/material.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Constant } from './services/common/constant';
import { SuccessBookingComponent } from './dialogs/success-booking/success-booking.component';
import { AddCustomerComponent } from './dialogs/add-customer/add-customer.component';
import { AddTeamComponent } from './dialogs/add-team/add-team.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AddDriverComponent } from './dialogs/add-driver/add-driver.component';
import { ChoosePlansComponent } from './dialogs/choose-plans/choose-plans.component';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { DummyBookingsComponent } from './dialogs/dummy-bookings/dummy-bookings.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { NumberModuleModule } from './core/number-module/number-module.module';
import { ScriptLoadService } from './services/maps.service';
import { InitalserviceService } from './services/initalservice.service';
import { CommonService } from './services/common/common.service';
import { ImageComponent } from './dialogs/image/image.component';
import { PermissionComponent } from './dialogs/permission/permission.component';
import { TimeIntervalComponent } from './dialogs/time-interval/time-interval.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { AddDocumentComponent } from './dialogs/add-document/add-document.component';
import { AddCmsComponent } from './dialogs/add-cms/add-cms.component';
import { AddSubAdminComponent } from './dialogs/add-sub-admin/add-sub-admin.component';
import { FullPayComponent } from './dialogs/full-pay/full-pay.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareBookingsComponent } from './dialogs/share-bookings/share-bookings.component';
import { AddSubscriptionComponent } from './dialogs/add-subscription/add-subscription.component';
import { RoleService } from './services/role/role.service';
import { DriverListComponent } from './dialogs/driver-list/driver-list.component';
import { BookingListingsComponent } from './dialogs/booking-listings/booking-listings.component';
import { SurgeChargesComponent } from './dialogs/surge-charges/surge-charges.component';
import { DailogServiceService } from './dialogs/dailog-service.service';
import { AddBankComponent } from './dialogs/add-bank/add-bank.component';
import { AddWalkthroughComponent } from './dialogs/add-walkthrough/add-walkthrough.component';
import { AddTemplateFormComponent } from './dialogs/add-template-form/add-template-form.component';
import { SafeHtmlPipePipe } from './core/safe-html-pipe.pipe';
import { AddVehicleComponent } from './dialogs/add-vehicle/add-vehicle.component';
import { VehicleModelComponent } from './dialogs/vehicle-model/vehicle-model.component';
import { AddAirportChargeComponent } from './dialogs/add-airport-charge/add-airport-charge.component';
import {AddB2bComponent} from './dialogs/add-b2b/add-b2b.component';
import { VerifyDocumentsComponent } from './dialogs/verify-documents/verify-documents.component';
import { ViewDocumentsComponent } from './dialogs/view-documents/view-documents.component';
import { AddSlotModalComponent } from './dialogs/add-slot-modal/add-slot-modal.component';
import { DocumentModalComponent } from './dialogs/document-modal/document-modal.component';
import { AddQuestionAnswerComponent } from './dialogs/add-question-answer/add-question-answer.component';
import { DriverDocFillComponent } from './dialogs/driver-doc-fill/driver-doc-fill.component';
import { ShowRatingModalComponent } from './dialogs/show-rating-modal/show-rating-modal.component';
import { ShowDeliveyProofComponent } from './dialogs/show-delivey-proof/show-delivey-proof.component';
import { ShowDriverByVehcileComponent } from './dialogs/show-driver-by-vehcile/show-driver-by-vehcile.component'
import { DecryptionInterceptor } from './services/interceptor/set/decryption.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AddWalletComponent } from './dialogs/add-wallet/add-wallet.component';
import { AddEditConductorComponent } from './dialogs/add-edit-conductor/add-edit-conductor.component';
import { ViewImageComponent } from './dialogs/view-image/view-image.component';
import { AddEditTaxComponent } from './dialogs/add-edit-tax/add-edit-tax.component';
import { NgxEditorModule } from 'ngx-editor';
export function startupServiceFactory(InitialService: InitalserviceService): Function {
  return () =>
    InitialService;
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SuccessBookingComponent,
    AddCustomerComponent,
    AddTeamComponent,
    AddDriverComponent,
    ChoosePlansComponent,
    DummyBookingsComponent,
    ImageComponent,
    PermissionComponent,
    TimeIntervalComponent,
    AddDocumentComponent,
    AddCmsComponent,
    AddSubAdminComponent,
    FullPayComponent,
    ShareBookingsComponent,
    AddSubscriptionComponent,
    DriverListComponent,
    BookingListingsComponent,
    SurgeChargesComponent,
    AddBankComponent,
    AddWalkthroughComponent,
    AddTemplateFormComponent,
    AddVehicleComponent,
    VehicleModelComponent,
    AddAirportChargeComponent,
    AddB2bComponent,
    VerifyDocumentsComponent,
    ViewDocumentsComponent,
    AddSlotModalComponent,
    DocumentModalComponent,
    AddQuestionAnswerComponent,
    DriverDocFillComponent,
    ShowRatingModalComponent,
    ShowDeliveyProofComponent,
    ShowDriverByVehcileComponent,
    AddWalletComponent,
    AddEditConductorComponent,
    ViewImageComponent,
    AddEditTaxComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    NumberModuleModule,
    NgxIntlTelInputModule,
    GooglePlaceModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ColorChromeModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      }
  }),
  NgxEditorModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitalserviceService],
      multi: true,
    },
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DecryptionInterceptor,
      multi: true,
    },
   
    
   
    apiList,
    Constant,
    ScriptLoadService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
