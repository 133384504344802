
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/common.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DecryptionInterceptor implements HttpInterceptor {
  constructor(private decryptionService: CommonService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let decryptedResponse
          if(event.body.statusCode ===200){
             decryptedResponse = this.decryptionService.decryptData(
              event.body.data
            );
            return event.clone({ body: JSON.parse(decryptedResponse) });
          }else{
            return event.clone({ body: event.body });
          }
        }
        return event;
      })
    );
  }
}
