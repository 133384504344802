import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Editor } from 'ngx-editor';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpsService } from 'src/app/services/http/http.service';
@Component({
  selector: 'app-add-cms',
  templateUrl: './add-cms.component.html',
  styleUrls: ['./add-cms.component.scss']
})
export class AddCmsComponent implements OnInit {


  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote'
  ];

  cmstype: any[] = [
    { name: 'LOGIN', value: 'LOGIN' },
    { name: 'SIGNUP', value: 'SIGNUP' },
    { name: 'SIDEBAR', value: 'SIDEBAR' }
  ]

  cmsRequired: any[] = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ]

  editors: any = {
    editor: new Editor(),
  };
  cmsForm: FormGroup
  submitted: boolean = false
  id: any;
  type: any;
  cmsData: any;
  required: boolean = false;
  isRequired: any
  constructor(private fb: FormBuilder, private https: HttpsService,
    private commonService: CommonService, public dialogRef: MatDialogRef<AddCmsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.createForm();

    if (this.data) {
      this.type = this.data.type;
      this.id = this.data.data
      if (this.type != 'add') {
        this.getById()
      }
    }
  }

  createForm() {
    this.cmsForm = this.fb.group({
      cmsTitle: ['', [Validators.required]],
      cmsContent: ['', [Validators.required]],
      showType: [[], [Validators.required]],
      isCheckBox:['']
    });
  }

  btn_add() {
    this.submitted = true;
    if (this.cmsForm.valid) {
      const body = this.cmsForm.value;
      if (body['showType'] == 'SIDEBAR') {
       delete body['isCheckBox']
      }
      this.type == 'add' && this.https.httpPostWithHeader('cms', this.cmsForm.value, true).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.snackbarSuccess(res.message, '', 2000);
          this.dialogRef.close('yes');
        }
      })

      this.type == 'edit' && this.https.httpPutWithHeaderBodyAndId('cms', this.id, this.cmsForm.value, false).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.commonService.snackbarSuccess(res.message, '', 2000);
          this.dialogRef.close('yes');
        }
      })
    }
  }

  getById() {
    this.https.httpGetHeaderById('cms', this.id).subscribe((res: any) => {
      if (res.statusCode === 200) {
        console.log('res: ', res);
        this.cmsData = res.data;
        if(this.cmsData.showType.includes('LOGIN') || this.cmsData.showType.includes('SIGNUP')){ 
          this.required = true
        }else {
          this.required = false
        }
        this.cmsForm.patchValue(this.cmsData);
      }
    })
  }

  btn_change(value) {
    if(value.includes('LOGIN') || value.includes('SIGNUP')){ 
      this.required = true
    }else {
      this.required = false
    }
  }

}
